// hooks
import { useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import { Stack, Chip, Divider } from '@mui/material'

// components
import DropdownSelect from '../../DropdownSelect'
import RatingInputElement from 'src/components/form/Rating'

// types
import { ChangeEvent, FC, SyntheticEvent } from 'react'
import {
  InputElement,
  LabelPlacement,
  RatingElement,
  RatingPrecision,
} from '../../../../types/redux/layout'

// assets
import {
  labelPlacementIcons,
  ratingPrecisionIcons,
} from '../../../../assets/settings'

interface RatingElementSettingsProps {
  idx: number
  handleUpdateInput: (options: Partial<InputElement>) => {
    payload: {
      idx: number
      options: Partial<InputElement>
    }
    type: string
  }
}

const RatingElementSettings: FC<RatingElementSettingsProps> = ({
  idx,
  handleUpdateInput,
}) => {
  const texts = useLanguage()

  const element = useAppSelector((state) => state.layout[idx] as RatingElement)

  return (
    <>
      <Stack direction='row' spacing={2}>
        <DropdownSelect
          {...{
            label: texts.inputLabelRatingPrecision,
            iconMatches: ratingPrecisionIcons,
            value: element.precision,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              handleUpdateInput({
                precision: Number(event.target.value) as RatingPrecision,
              }),
          }}
        />
        <DropdownSelect
          {...{
            label: texts.inputLabelLabelPlacement,
            iconMatches: labelPlacementIcons,
            value: element.labelPlacement,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              handleUpdateInput({
                labelPlacement: event.target.value as LabelPlacement,
              }),
          }}
        />
      </Stack>
      <Divider textAlign='left'>
        <Chip
          label={texts.sectionHeaderDefaultValue}
          color='default'
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            borderRadius: 1,
          }}
        />
      </Divider>
      {/* <Stack direction='row' spacing={1}> */}
      <RatingInputElement
        {...{
          element,
          value: element.default as number | null,
          onChange: (
            _: SyntheticEvent<Element, Event>,
            newValue: number | null
          ) =>
            handleUpdateInput({
              default: newValue,
            }),
        }}
      />
      {/* <Typography component='legend'>{element.label}</Typography>
        <Rating
          precision={element.precision}
          value={element.default as number | null}
          onChange={(
            _: SyntheticEvent<Element, Event>,
            newValue: number | null
          ) =>
            handleUpdateInput({
              default: newValue,
            })
          }
        /> */}
      {/* </Stack> */}
    </>
  )
}

export default RatingElementSettings
