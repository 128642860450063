import axios from 'axios'

const api = axios.create({
  baseURL:
    window.location.origin === 'http://localhost:3000'
      ? 'http://localhost:5000'
      : `${window.location.origin}/backend`,
  headers: {
    'Access-Control-Allow-Origin':
      window.location.origin === 'http://localhost:3000'
        ? 'http://localhost:5000'
        : `${window.location.origin}/backend`,
  },
})

export default api
