// types
import { DataTable, DataValue } from '@tableau/extensions-api-types'
import { SupportedLocales } from 'src/types/redux/appearance'

// assets
import { localeOptions } from 'src/redux/appearance'

const tableauFunctions = [
  'MIN',
  'MAX',
  'MAX.',
  'ANZ',
  'ANZE',
  'ATTR',
  'VARP',
  'SUM',
  'MEDIAN',
  'DURSCHN',
  'STDABW',
  'STDABWP',
  'VAR',
]

export const getLocale = () => {
  let loc = tableau.extensions?.environment?.locale
  let locale: SupportedLocales = 'enUS'
  if (loc) {
    loc = loc.replace('-', '')
    let reg = loc.slice(2, 4)
    loc = loc.slice(0, 2) + reg.toUpperCase()
    if (localeOptions.indexOf(loc) > -1) locale = loc as SupportedLocales
    else locale = 'enUS' as SupportedLocales
  }
  return locale
}

export const createPossibleTableauDimensions = (key: string) =>
  tableauFunctions.map((f) => `${f}(${key})`)

export const findColIdx = ({
  dataTable,
  key,
}: {
  dataTable: DataTable
  key: string
}) => {
  const index = dataTable.columns.find(
    (col) =>
      col.fieldName.slice(0, key.length) === key ||
      col.fieldId.slice(-key.length - 1, -1) === key
  )?.index
  return typeof index === 'number' ? index : -1
}

export const findRow = ({
  dataTable,
  key,
  val,
}: {
  dataTable: DataTable
  key: string
  val: number | string | boolean
}) => {
  const colIdx = findColIdx({ dataTable, key })
  return typeof colIdx === 'number'
    ? dataTable.data.find((row) => row[colIdx].nativeValue === val)
    : undefined
}

export const findRows = ({
  dataTable,
  key,
  val,
}: {
  dataTable: DataTable
  key: string
  val: number | string | boolean
}) => {
  const colIdx = findColIdx({ dataTable, key })
  return colIdx
    ? dataTable.data.filter((row) => row[colIdx].nativeValue === val)
    : undefined
}

export const getValFromRow = ({
  row,
  colIdx,
}: {
  row: DataValue[]
  colIdx: number
}) => row[colIdx].nativeValue

export const getCbDataTable = async ({
  configName,
  worksheet,
}: {
  configName: string
  worksheet: string
}) => {
  const commentWorksheet =
    tableau.extensions.dashboardContent?.dashboard.worksheets.find(
      (ws) => ws.name === worksheet
    )
  if (commentWorksheet) {
    return commentWorksheet.getDataSourcesAsync().then(async (dss) => {
      const dsFilter = await Promise.all(
        dss.map((ds) =>
          ds
            .getLogicalTablesAsync()
            .then(
              (lts) =>
                !!lts.find(
                  (lt) =>
                    lt.id.slice(0, `cb_comments_${configName}`.length) ===
                    `cb_comments_${configName}`
                )
            )
        )
      )
      const dataSources = dss.filter((_, i) => dsFilter[i])

      // throw error if multiple are found
      if (dataSources.length > 1)
        console.log(
          `more than one data source containing the cb_comments_${configName} have been found`
        )

      if (dataSources.length > 0)
        return dataSources[0].getLogicalTablesAsync().then((lts) => {
          const logicalTable = lts.find(
            (lt) =>
              lt.id.slice(0, `cb_comments_${configName}`.length) ===
              `cb_comments_${configName}`
          )
          if (logicalTable)
            return dataSources[0]
              .getLogicalTableDataAsync(logicalTable.id)
              .then((ltd) => ltd)
          else return new Promise<undefined>((resolve) => resolve(undefined))
        })
    })
  } else return new Promise<undefined>((resolve) => resolve(undefined))
}
