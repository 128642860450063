// mui
import { Stack, AppBar, Toolbar } from '@mui/material'

// components
import CommentingDialogButtons from './CommentingDialogButtons'

// types
import { MarkSelection } from '../../types/marks'
import { FC } from 'react'

interface DialogAppBarProps {
  handleSave: (refresh: boolean) => void
  edit: boolean
  markSelection: MarkSelection
}

const DialogAppBar: FC<DialogAppBarProps> = ({
  handleSave,
  edit,
  markSelection,
}): JSX.Element => {
  return (
    <AppBar
      position='fixed'
      elevation={24}
      color='default'
      sx={{
        top: 'auto',
        bottom: 0,
        flexDirection: 'row',
      }}
    >
      <Toolbar sx={{ width: '100%' }}>
        <Stack
          width='100%'
          direction='row'
          justifyContent='flex-end'
          spacing={2}
        >
          <CommentingDialogButtons
            {...{
              edit,
              handleSave,
              markSelection,
            }}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default DialogAppBar
