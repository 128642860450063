// hooks
import { useContext } from 'react'

// context
import { LanguageContext } from 'src/components/providers/LanguageProvider'

export const languageOptions = {
  en: 'English',
  de: 'Deutsch',
}

export type Language = 'en' | 'de'

export const useLanguage = () => {
  const languageContext = useContext(LanguageContext)
  return languageContext.dictionary
}
