import * as locales from '@mui/material/locale'
import { languages } from 'src/languages'

export enum OptionsButtonVariant {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export type SupportedLocales = keyof typeof locales

export type ColorKey =
  | 'dialogCancelColor'
  | 'dialogSaveColor'
  | 'dialogRefreshColor'

export type AppearanceState = {
  popupDialog: boolean
  dashboardButtonVariant: OptionsButtonVariant
  addCommentText: string
  editCommentText: string
  addCommentColor: string
  editCommentColor: string
  dialogButtonsGrouped: boolean
  dialogButtonsVariant: OptionsButtonVariant
  dialogCancelColor: string
  dialogSaveColor: string
  dialogRefreshColor: string
  locale: SupportedLocales
  darkMode: boolean
}

export type Texts = {
  [textIdentifier: string]: { [language in Language]: string }
}

export type Language = typeof languages[number]
