import { TextDictionary } from './../types/languages'

export const en: TextDictionary = {
  // alert texts
  alertTextCommentableWorksheetCommentUid:
    "Convert 'Comment Uid' to a dimension, then drag & drop it onto tooltip to make it available as 'ATTR(Comment Uid)'.",
  alertTextCommentableWorksheetDataSource:
    "Add the database table created by Combleau to the worksheets data either as a new data source or intra-data souce join: 'cb_comments_",
  alertTextCommentableWorksheetDataSourceLive:
    "Make sure the data source containing the Combleau created database table is 'live'.",
  alertTextCommentableWorksheetSiblingsUid:
    "Convert 'Siblings Uid' to a dimension, then drag & drop it onto tooltip to make it available as 'ATTR(Siblings Uid)'.",
  alertTextMultipleSiblingsUids:
    'Found multiple siblings uids in the current mark selection. Make sure the selection only contains members of a single siblings uid at a time.',
  alertTextMoreMarksThanComments:
    'Found more marks than comment uids, but at least one comment uid in the current mark selection. Either select only non commented on marks to add a new comment or only marks with the same sibling uid to edit existing comments.',
  alertTextNonRelatedComments:
    'Found multiple non related comment in the current mark selection. Make sure only single or sibling comments are included in the selection if you want to edit.',
  alertTextUsernameMissing:
    'Create a calculated field containing only the USERNAME() function and add it to either rows or columns of the username sheet.',
  alertTextUsernameSheetMissing:
    "Add a new worksheet and rename it to 'Username'. Then, put it on the dashboard Combleau is to be used on.",
  alertTextConfigurationMissing:
    "Click 'Configure' in the context menu of the dashboard object containing this extension and configure Combleau. Click save to apply the configuration.",
  alertTextFaultyServerConnection:
    'Could not connect to the server. Please contact your server administrator if this problem persists.',
  // alert titles
  alertTitleCommentableWorksheetConfig: 'Configure Commentable Worksheet',
  alertTitleConfigurationMissing: 'Configure Combleau',
  alertTitleFaultyMarkSelection: 'Faulty Mark Selection',
  alertTitleFaultyServerConnection: 'Faulty Server Connection',
  alertTitleUsernameSheetMissing: 'Add Username Sheet',
  // buffers
  bufferDatabaseCheck:
    'Checking on the database state and making changes if needed.',
  bufferFetchingTableauData: 'Fetching comment data from Tableau.',
  bufferSavingConfig: 'Configuration setup to the database.',
  bufferSavingComment: 'Saving comment to the database.',
  bufferTableauCheck: 'Fetching worksheet information from dashboard.',
  bufferUsernameCheck: 'Fetching username from dashboard.',
  bufferFetchingSavedFields: 'Fetching comment data from Tableau.',
  bufferFetchingSiblings: 'Fetching comment siblings dimensions from Tableau.',
  // button texts
  buttonTextAddFormElement: 'Add Element',
  buttonTextAddDropdownOption: 'Add Option',
  buttonTextAddRadio: 'Add Radio',
  buttonTextCancel: 'Cancel',
  buttonTextDelete: 'Delete',
  buttonTextDiscard: 'Discard All Changes',
  buttonTextInputElement: 'Input',
  buttonTextLoad: 'Load',
  buttonTextSave: 'Save',
  buttonTextSaveClose: 'Save & Close',
  buttonTextRefresh: 'Refresh',
  buttonTextStyleElement: 'Style',
  buttonTextThief: 'Thief',
  buttonTextUpdate: 'Edit',
  // database table preview
  databaseTablePreviewMetadata: '...metadata...',
  databaseTablePreviewInputElements: '...input elements...',
  databaseTablePreviewDimensions: '...dimensions...',
  // dialog title
  dialogTitleThief: 'Thief',
  // dialog content
  dialogContentThief: 'Select the setup to rob from.',
  // dropdown options
  dropdownOptionTextTypeHeading: 'Heading',
  dropdownOptionTextTypeSubtitle: 'Subtitle',
  dropdownOptionTextTypeParagraph: 'Paragraph',
  dropdownOptionDirectionRow: 'Horizontal',
  dropdownOptionDirectionCol: 'Vertical',
  // form helpers
  formHelperDatabaseColDuplicate:
    'Database column name duplicates are not allowed.',
  formHelperDatabaseColMissing: 'A database column name is required.',
  formHelperDropdownOptionExistsError: 'This option already exists.',
  formHelperFieldRequired: 'This field is required.',
  formHelperRadioExists: 'This radio option already exists.',
  formHelperMissingConfigName: 'Field required.',
  // input element types
  inputElementTypeRadio: 'Radiogroup',
  inputElementTypeRating: 'Rating',
  inputElementTypeDatepicker: 'Datepicker',
  // input labels
  inputLabelAddCommentText: 'Add Comment Button Text',
  inputLabelButtonVariant: 'Variant',
  inputLabelButtonArrangement: 'Arrangement',
  inputLabelColor: 'Color',
  inputLabelCommentableWorksheets: 'Commentable Worksheets',
  inputLabelConfigName: 'Configuration Name',
  inputLabelContent: 'Content',
  inputLabelDatabaseColumnName: 'Database Column Name',
  inputLabelDatepickerPreselectCurrentDate: 'Preselect Current Date',
  inputLabelDimension: 'Dimension',
  inputLabelDividerText: 'Text',
  inputLabelDividerChip: 'Chip',
  inputLabelEditCommentText: 'Edit Comment Button Text',
  inputLabelElementDependency: 'Dependency',
  inputLabelElementDependencyRevealingValue: 'Revealing Value',
  inputLabelEnableCommentingOnWorksheet: 'Enable Commenting on this Worksheet',
  inputLabelFormLocation: 'Form Location',
  inputLabelFormInPopupDialog: 'Popup Dialog',
  inputLabelFormOnDashboard: 'Tableau Dashboard',
  inputLabelInputElementType: 'Input Element Type',
  inputLabelLabelPlacement: 'Label Placement',
  inputLabelLevel: 'Level',
  inputLabelLocale: 'Locale',
  inputLabelInputLabel: 'Label',
  inputLabelMultiInputElementDirection: 'Direction',
  inputLabelMode: 'Mode',
  inputLabelNewDropdownOption: 'New Option',
  inputLabelRequired: 'Required',
  inputLabelSavedConfigs: 'Saved Configuration',
  inputLabelSelReq: 'Comment',
  inputLabelStyleElementType: 'Style Element Types',
  inputLabelStyleElementIdentifier: 'Identifier',
  inputLabelTextType: 'Text Type',
  inputLabelTextAlignment: 'Text Alignment',
  inputLabelRatingPrecision: 'Precision',
  inputLabelRequireTableauMarkToComment: 'Require Tableau Mark to Comment',
  inputLabelTextfieldMultirow: 'Multirow',
  inputLabelTextfieldFixedNumberOfRows: 'Fixed Number of Rows',
  inputLabelTextfieldMaximumNumberOfRows: 'Maximum Number of Rows',
  inputLabelTextfieldNumberOfRows: 'Number of Rows',
  inputLabelWorksheetDimensions: 'Worksheet Dimensions',
  inputLabelWorksheets: 'Worksheets',
  none: 'None',
  // paragraphs
  paragraphEditCommentCheckMarksToEdit:
    'Check all dimensions to apply changes to.',
  paragraphEditCommentNoCommentUid: 'Choose a comment uid to edit.',
  // options
  optionButtonLayoutGrouped: 'Grouped',
  optionButtonLayoutSeparate: 'Separate',
  optionButtonVariantContained: 'Contained',
  optionButtonVariantOutlined: 'Outlined',
  optionButtonVariantText: 'Text',
  // section headers
  sectionHeaderDashbordButtons: 'Dashboard Buttons',
  sectionHeaderDefaultValue: 'Default Value',
  sectionHeaderDialogActionButtons: 'Dialog Buttons',
  sectionHeaderDropdownOptions: 'Options',
  sectionHeaderForm: 'Form',
  sectionHeaderFormElements: 'Form Elements',
  sectionHeaderGeneral: 'General',
  sectionHeaderRadioButtons: 'Radios',
  sectionHeaderFundamental: 'Fundamental',
  sectionHeaderMarks: 'Marks',
  sectionHeaderPreviewDialogButtons: 'Preview Dialog Buttons',
  sectionHeaderPreviewDashboardButtons: 'Preview Dashboard Buttons',
  sectionHeaderPreviewDatabaseTable: 'Database Table Columns',
  sectionHeaderPreviewForm: 'Preview Form',
  sectionHeaderWorksheets: 'Worksheets',
  sectionHeaderWorksheetDimensions: 'Worksheet Dimensions',
  selected: 'selected',
  // snackbars
  snackbarConfigExists:
    'The setup name already exists on the database. Please rename the setup to find a name unique to the database.',
  snackbarTableauObjectIdMissing:
    'Could not resolve the tableau object id of the dashboard extension.',
  snackbarUsernameMissing: 'Could not resolve username.',
  snackbarDimensionAlreadyCommentedOn:
    'A comment already exists in the database for at least one of your selected marks.',
  styleElementTypeDivider: 'Divider',
  styleElementTypeText: 'Text',
  // tab labels
  tabLabelAppearance: 'Appearance',
  tabLabelDimensions: 'Dimensions',
  tabLabelHelp: 'Help',
  tabLabelLayout: 'Layout',
  // table heads
  tableHeadCategory: 'Category',
  tableHeadDataType: 'Data Type',
  tableHeadColumnName: 'Column Head',
  // toogle button options
  toggleButtonOptionModeLight: 'Light',
  toggleButtonOptionModeDark: 'Dark',
  toggleButtonOptionSelNotReq: 'Without Link to Data',
  toggleButtonOptionSelReq: 'Specific Data Points',
  // tooltips
  tooltipAddDropdownOption: 'Press enter to add the new dropdown option.',
  tooltipAddRadio: 'Press enter to add the new radio.',
  tooltipConfigNameRequired: 'A name for the configuration is required.',
  tooltipConfigNameSavedAlready:
    'The configuration has already been saved under this name on the database.',
  tooltipDatabaseIdentifierImmutablePostSave:
    'The database identifiers cannot be changed after having published the setup to the server and thus created the database tables.',
  tooltipDeleteCommentForAllDimensions:
    'Lösche Kommentar für alle Dimensionen.',
  tooltipDimensionOptionDisabledSinceInitialized:
    'This dimension cannot be removed, since a corresponding column has already been created in the database table.',
  tooltipDiscardConfigChanges: 'Discard all unsaved changes.',
  tooltipElementDependencyNoInputElementError:
    'No input element exists, thus no dependency can be created.',
  tooltipElementDependencyNoOtherInputElementError:
    'No other input element exists, thus no dependency can be created.',
  tooltipFormLayoutConfigErrors:
    'The form layout configuration contains errors.',
  tooltipNoInputElement: 'At least one input element is required.',
  tooltipNothingToSave:
    'Nothing to save. No changes to the configuration have been recognized.',
  tooltipNoChangesToDiscard: 'There are no changes to be discarded.',
  tooltipNameChangeAfterSave:
    'The current configuration cannot be renamed, since it has already been saved to the database under its current name.',
  tooltipThiefCurrentDimensionsAlreadySaved:
    'The current configuration has already been saved to the database. Loading different dimensions might delete existing dimension, which is not allowed.',
  tooltipThiefCurrentLayoutAlreadySaved:
    'The current configuration has already been saved to the database. Loading a different form layout might delete existing input elements, which is not allowed.',
  tooltipSaveCommentFormStillContainsErrors:
    'Saving not possible. The form still contains errors.',
  tooltipInputFieldWithoutDbColName:
    'At least one input element without database column name existant.',
  tooltipSaveCommentNoValidMarkSelected:
    'Saving not possible. No valid mark selection made.',
  tooltipWorksheetOptionDisabledSinceInitialized:
    'This worksheet cannot be removed anymore, since some of its dimensions have corresponding columns in the database table already.',
}
