// hooks
import { useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  AddCircle,
  Circle,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
  MoreVert,
} from '@mui/icons-material'
import {
  Stack,
  TextField,
  MenuItem,
  Divider,
  Chip,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Tooltip,
  Typography,
  FormHelperText,
  InputLabel,
  Input,
} from '@mui/material'

// types
import { ChangeEvent, MouseEvent, KeyboardEvent } from 'react'
import {
  Direction,
  InputElement,
  LabelPlacement,
  MuiColor,
  RadiogroupElement,
} from '../../../../types/redux/layout'
import { FC } from 'react'

// assets
import { textFieldStyleProps } from '../../../../assets/helpers/mui'
import {
  directionIcons,
  directions,
  labelPlacementIcons,
  labelPlacements,
  muiColors,
} from '../../../../assets/settings'
import { upperCaseFirstLetter } from '../../../../assets/helpers/stringFormatters'

interface RadioSettingsProps {
  idx: number
  handleUpdateInput: (options: Partial<InputElement>) => {
    payload: {
      idx: number
      options: Partial<InputElement>
    }
    type: string
  }
}

const RadioSettings: FC<RadioSettingsProps> = ({ idx, handleUpdateInput }) => {
  const texts = useLanguage()

  const element = useAppSelector(
    (state) => state.layout[idx] as RadiogroupElement
  )
  const [label, setLabel] = useState<string>('')

  const addLabel = () => {
    handleUpdateInput({
      labels: [...element.labels, label],
    })
    setLabel('')
  }

  const moveLabel = (i: number, d: number) => {
    let labels = element.labels.slice()
    labels.splice(i + d, 0, labels.splice(i, 1)[0])
    handleUpdateInput({
      labels,
    })
  }

  const deleteLabel = (i: number) => {
    let labels = element.labels
    labels.splice(i, 1)
    handleUpdateInput({
      labels,
    })
  }

  let error: boolean = !label ? false : element.labels.indexOf(label) > -1

  return (
    <Stack key={idx} direction='column' spacing={3}>
      <Stack direction='row' spacing={2}>
        <TextField
          {...textFieldStyleProps(texts.inputLabelMultiInputElementDirection)}
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                {(element.direction === 'row' && <MoreHoriz />) ||
                  (element.direction === 'column' && <MoreVert />)}
              </InputAdornment>
            ),
          }}
          SelectProps={{
            renderValue: (dir) => {
              const direction = dir as Direction
              return (
                <>
                  {(direction === 'row' && texts.dropdownOptionDirectionRow) ||
                    (direction === 'column' &&
                      texts.dropdownOptionDirectionCol)}{' '}
                </>
              )
            },
            size: 'small',
          }}
          value={element.direction}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateInput({
              direction: event.target.value as Direction,
            })
          }
        >
          {directions.map((direction) => (
            <MenuItem key={direction} value={direction}>
              <ListItemIcon>{directionIcons[direction]}</ListItemIcon>
              <ListItemText
                primary={
                  (direction === 'row' && texts.dropdownOptionDirectionRow) ||
                  (direction === 'column' && texts.dropdownOptionDirectionCol)
                }
              />
            </MenuItem>
          ))}
        </TextField>
        <TextField
          {...textFieldStyleProps(texts.inputLabelLabelPlacement)}
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                {labelPlacementIcons[element.labelPlacement]}
              </InputAdornment>
            ),
          }}
          SelectProps={{
            renderValue: (lp) => <>{upperCaseFirstLetter(lp as string)}</>,
            size: 'small',
          }}
          value={element.labelPlacement}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateInput({
              labelPlacement: event.target.value as LabelPlacement,
            })
          }
        >
          {labelPlacements.map((labelPlacement) => (
            <MenuItem key={labelPlacement} value={labelPlacement}>
              <ListItemIcon>{labelPlacementIcons[labelPlacement]}</ListItemIcon>
              <ListItemText primary={upperCaseFirstLetter(labelPlacement)} />
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack direction='row' spacing={2}>
        <TextField
          {...textFieldStyleProps(texts.inputLabelColor)}
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Circle color={element.color} />
              </InputAdornment>
            ),
          }}
          SelectProps={{
            renderValue: (color) => (
              <>
                {(color as string).charAt(0).toUpperCase() +
                  (color as string).slice(1)}
              </>
            ),
            size: 'small',
          }}
          value={element.color}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateInput({
              color: event.target.value as MuiColor,
            })
          }
        >
          {muiColors.map((muiColor) => (
            <MenuItem key={muiColor} value={muiColor}>
              <ListItemIcon color={muiColor}>
                <Circle color={muiColor} />
              </ListItemIcon>
              <ListItemText primary={upperCaseFirstLetter(muiColor)} />
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Divider textAlign='left'>
        <Chip
          label={texts.sectionHeaderRadioButtons}
          color='default'
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            borderRadius: 1,
          }}
        />
      </Divider>
      <FormControl
        variant='standard'
        size='small'
        error={error}
        sx={{
          '& .Mui-error': {
            color: 'warning.main',
          },
          '&  .MuiFormHelperText-root': {
            color: 'warning.main',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-error fieldset': {
              borderColor: 'warning.main',
            },
          },
        }}
      >
        <InputLabel>{texts.buttonTextAddRadio}</InputLabel>
        <Input
          size='small'
          fullWidth
          value={label || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setLabel(event.target.value)
          }
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === 'Enter' && !error && label.length !== 0)
              addLabel()
          }}
          endAdornment={
            <InputAdornment position='end'>
              <Tooltip
                title={
                  <Typography variant='body2'>
                    {texts.tooltipAddRadio}
                  </Typography>
                }
              >
                <span>
                  <IconButton
                    disabled={error || label.length === 0}
                    color='primary'
                    onClick={addLabel}
                    onMouseDown={(event: MouseEvent<HTMLButtonElement>) =>
                      event.preventDefault()
                    }
                    edge='end'
                  >
                    {<AddCircle />}
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          }
        />
        <FormHelperText>
          {!label &&
            element.labels.indexOf(label) > -1 &&
            texts.formHelperRadioExists}
        </FormHelperText>
      </FormControl>
      <List dense>
        {element.labels.map((label, i) => (
          <ListItem
            key={i}
            secondaryAction={
              <>
                <IconButton
                  edge='end'
                  color='primary'
                  onClick={() => moveLabel(i, -1)}
                  disabled={i === 0}
                >
                  <KeyboardArrowUp />
                </IconButton>
                <IconButton
                  edge='end'
                  color='primary'
                  onClick={() => moveLabel(i, 1)}
                >
                  <KeyboardArrowDown />
                </IconButton>
                <IconButton
                  edge='end'
                  color='error'
                  onClick={() => deleteLabel(i)}
                >
                  <Delete />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Divider textAlign='left'>
        <Chip
          label={texts.sectionHeaderDefaultValue}
          color='default'
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            borderRadius: 1,
          }}
        />
      </Divider>
      <FormControl>
        <FormLabel>{element.label}</FormLabel>
        <RadioGroup
          row={element.direction === 'row'}
          value={element.default || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateInput({
              default: (event.target as HTMLInputElement).value,
            })
          }
        >
          <FormControlLabel
            labelPlacement={element.labelPlacement}
            value=''
            control={<Radio color={element.color} />}
            label={texts.none}
            sx={{ fontStyle: 'italic' }}
          />
          {element.labels.map((label, i) => (
            <FormControlLabel
              key={i}
              labelPlacement={element.labelPlacement}
              value={label}
              control={<Radio color={element.color} />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  )
}

export default RadioSettings
