import { SvgIcon, SvgIconProps } from '@mui/material'
import { FC } from 'react'

const ThiefIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...{ ...props, viewBox: '0 0 487.716 487.716' }}>
      <path
        d='M51.936,194.7l5.6,9.7c1.5-0.2,3-0.3,4.6-0.3c5.9,0,11.7,0,17.5,0h6l5.4-9.4c2.6-4.6-0.7-10.3-5.9-10.3h-27.3
			C52.636,184.5,49.336,190.2,51.936,194.7z'
      />
      <path
        d='M330.236,394.6c35.7-14.7,70.9-29.6,106.6-44.4c10.4-3.2,18-10.5,18.2-22.4c-0.1-12.6-4.2-23.4-15.7-30.4
			c-7.8-4.9-16-4.2-24.3-0.7c-21.9,8.9-43.3,17.9-65.2,26.8c-2.1,0.9-4.1,1.8-6.7,2.4c-0.9-2.1-1.8-4.1-2.6-6.2
			c-8.9-21.9-8.2-19.3-17.3-40.7c-0.3-0.7-3.5-8.3-6.6-14.7c-5.5,17.8-17.4,31.8-27.7,40.2l-13.6,11.1c9.7,22.8,19.1,46,28.5,68.1
			C309.236,397,316.436,400.5,330.236,394.6z'
      />
      <path
        d='M85.436,268.5h-6.4h-15h-6.4c-8.3,0-15.4,5.2-16.9,12.4l-7.4,34.8c-4,19.4,10.8,37.6,30.6,37.6h15
			c19.8,0,34.6-18.2,30.6-37.6l-7.2-34.8C100.836,273.7,93.736,268.5,85.436,268.5z'
      />
      <path
        d='M200.736,338.8c17.8-10,35.7-20.4,53.7-30.9l22.1-17.9c3.4-2.6,38.3-32.5,15.4-69.2c-18-29-36-58-54-87
			c-0.9-2.1-2.4-3.8-3.8-6.1c1.9-0.4,3.5-0.3,4.7-0.4c21.2,0,42.8,0.2,64.2-0.3c4-0.1,5.8,1.2,8,4.4c7.6,12.2,15.3,24.5,22.9,36.7
			s21,15.9,31.9,8.9c12.8-6.2,15.5-20,7.9-32.2c-10.7-17.5-21.3-34.9-32-52.4c-4.1-6.8-9.3-9.5-17.2-9.8
			c-34.9,0.2-70.4,0.1-105.5,0.8c-6.4,0.3-13.6,2.5-19,6c-19.7,12-38.4,25.6-57.8,38.4c-10.9,7-15.8,16.5-15.8,29.1
			c-0.1,17.1-0.1,34.2-0.2,51.4c-0.3,2.2,0.1,4,0.1,6.9c-2.4,0.2-4.5-0.1-6.2-0.2c-19.5,0.1-38.3,0-57.8,0.1
			c-14.3-0.1-23.6,8.8-23.7,21.9s9.7,22.8,23.4,22.6c30.9-0.1,62.3,0,93.2,0c9.7-0.1,14.6-5,14.7-15.2c0.1-14.3-0.2-29.2-0.1-43.5
			c0.2-0.5,0.2-0.5,0.5-2.7c14.9,22.6,29.5,44.5,43.8,66.9c-1.8,1.6-3.4,2.6-5.4,3.5c-18.3,11.4-36.9,23.4-55.5,34.1
			c-9.1,5.5-12.7,13.1-12.9,23.4c0.2,44.7,0.4,89.4,0.2,133.8c0.1,16.6,10.7,27.2,27.6,27.8c16.4,0.4,26.8-6.9,29.8-20
			c0.5-2.7,0.5-5.5,0.3-7.9c-0.2-37.8-0.3-75.6-0.5-113.4C197.736,343.3,197.536,341,200.736,338.8z'
      />
      <path
        d='M80.236,52.9c0.4,28.7,24.1,52.1,52.8,51.7c29.2-0.2,52.3-23.4,52-52.2c0-29.7-23.4-52.3-53.1-52.4
			C103.436-0.1,80.036,23.6,80.236,52.9z'
      />
    </SvgIcon>
  )
}

export default ThiefIcon
