// hooks
import { useAppSelector } from './../../redux/hooks'
import { useLanguage } from '../../hooks/languages'

// mui
import { Alert, AlertTitle } from '@mui/material'

// components
import { Fragment } from 'react'

const ImplementationInstructions = () => {
  const texts = useLanguage()

  const tableau = useAppSelector((state) => state.tableau)

  const configName = useAppSelector((state) => state.config.name)

  const appearance = useAppSelector((state) => state.config.appearance)

  return (
    <>
      {tableau.databaseInitialized ? (
        <>
          <Alert severity={tableau.username.name ? 'success' : 'warning'}>
            <AlertTitle>{texts.alertTitleUsernameSheetMissing}</AlertTitle>
            <ol>
              {!tableau.username.sheetExists && (
                <li>{texts.alertTextUsernameSheetMissing}</li>
              )}
              {!tableau.username.name && (
                <li>{texts.alertTextUsernameMissing}</li>
              )}
            </ol>
          </Alert>
          <Alert severity={appearance ? 'success' : 'warning'}>
            <AlertTitle>{texts.alertTitleConfigurationMissing}</AlertTitle>
            {!appearance && texts.alertTextConfigurationMissing}
          </Alert>
          {Object.entries(tableau.worksheets).map(([key, value]) => {
            const done = Object.values(value).every((b) => b)
            return (
              <Fragment key={key}>
                <Alert severity={done ? 'success' : 'warning'}>
                  <AlertTitle>
                    {`${texts.alertTitleCommentableWorksheetConfig} '${key}'`}
                  </AlertTitle>
                  {!done && (
                    <ol>
                      {!value.commentingDataSourceExists && (
                        <li>
                          {`${texts.alertTextCommentableWorksheetDataSource}${configName}'.`}
                        </li>
                      )}
                      {!value.commentUidExists && (
                        <li>{texts.alertTextCommentableWorksheetCommentUid}</li>
                      )}
                      {!value.siblingsUidExists && (
                        <li>
                          {texts.alertTextCommentableWorksheetSiblingsUid}
                        </li>
                      )}
                      {!value.dataSourceIsLive && (
                        <li>
                          {texts.alertTextCommentableWorksheetDataSourceLive}
                        </li>
                      )}
                    </ol>
                  )}
                </Alert>
              </Fragment>
            )
          })}
        </>
      ) : (
        <Alert severity='error'>
          <AlertTitle>{texts.alertTitleFaultyServerConnection}</AlertTitle>
          {texts.alertTextFaultyServerConnection}
        </Alert>
      )}
    </>
  )
}

export default ImplementationInstructions
