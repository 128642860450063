export const checkQuotes = (string: string | undefined): boolean =>
  typeof string === 'string' &&
  ((string as string).includes('"') ||
    (string as string).includes("'") ||
    (string as string).includes(';'))
    ? false
    : true

export const checkExistsAndQuotes = (
  string: string | number | boolean | Date | undefined
) => (string ? checkQuotes(string as string) : false)
