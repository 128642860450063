// hooks
import { useEffect } from 'react'
import { SnackbarMessage, useSnackbar } from 'notistack'
import { useAppDispatch, useAppSelector } from '../redux/hooks'

// redux
import { snackbarsActions } from '../redux/snackbars'

// types
import { SnackbarKey } from 'notistack'
import { useLanguage } from './languages'

let displayed: SnackbarKey[] = []

const useNotifier = () => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const snackbars = useAppSelector((state) => state.snackbars)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id: SnackbarKey) => (displayed = [...displayed, id])
  const removeDisplayed = (id: SnackbarKey) =>
    (displayed = [...displayed.filter((key) => id !== key)])

  useEffect(() => {
    snackbars.forEach(
      ({ key, customMessage, messageKey, options = {}, dismissed }) => {
        if (dismissed) {
          closeSnackbar(key)
          return
        }
        if (displayed.includes(key)) return

        let message: SnackbarMessage

        if (messageKey)
          switch (messageKey) {
            case 'dimensionAlreadyCommentedOn':
              message = texts.snackbarDimensionAlreadyCommentedOn
              break
          }

        if (customMessage) message = customMessage

        enqueueSnackbar(message, {
          key,
          ...options,
          onExited: (_, myKey: SnackbarKey) => {
            dispatch(snackbarsActions.remove(myKey))
            removeDisplayed(myKey)
          },
        })

        storeDisplayed(key)
      }
    )
  }, [
    snackbars,
    closeSnackbar,
    enqueueSnackbar,
    dispatch,
    texts.snackbarDimensionAlreadyCommentedOn,
  ])
}

export default useNotifier
