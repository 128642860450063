import { Dayjs } from 'dayjs'
import {
  muiColors,
  alignments,
  labelPlacements,
  directions,
  ratingPrecisions,
  inputTypes,
  styleTypes,
  textAlignments,
  textTypes,
} from 'src/assets/settings'

export type MuiColor = typeof muiColors[number]
export type Alignment = typeof alignments[number]
export type LabelPlacement = typeof labelPlacements[number]
export type Direction = typeof directions[number]
export type RatingPrecision = typeof ratingPrecisions[number]

export type InputType = typeof inputTypes[number]

export type InputConfigErrors = {
  [key in 'dbColMissing' | 'dbColDuplicate' | 'dependence']: boolean
}

export type InputErrors = {
  [key in 'required' | 'type']: boolean
}

export type Value = Dayjs | boolean | string | number | null | undefined

export type DataType =
  | 'string?'
  | 'number?'
  | 'boolean?'
  | 'datetime?'
  | 'string'
  | 'number'
  | 'boolean'
  | 'datetime'

export type InputCore = {
  type: InputType
  visible: boolean
  dataType: DataType
  dbCol: string
  label: string
  required: boolean
  dependsOn: number
  enablingValue?: Value
  configErrors: InputConfigErrors
  default: Value
  value: Value
  inputErrors: InputErrors
}

export type InputElement = InputCore & {
  labels?: string[]
  direction?: Direction
  labelPlacement?: LabelPlacement
  color?: MuiColor
  precision?: RatingPrecision
  options?: string[]
  preselectCurrentDate?: boolean
  multirow?: boolean
  fixedRows?: boolean
  rows?: number
}

export type TextfieldElement = InputCore & {
  multirow: boolean
  fixedRows?: boolean
  rows?: number
}

export type RadiogroupElement = InputCore & {
  labels: string[]
  direction: Direction
  labelPlacement: LabelPlacement
  color: MuiColor
}

export type CheckboxSwitchElement = InputCore & {
  labelPlacement: LabelPlacement
  color: MuiColor
}

export type RatingElement = InputCore & {
  precision: RatingPrecision
  labelPlacement: LabelPlacement
}

export type DropdownElement = InputCore & {
  options: string[]
}

export type DatepickerElement = InputCore & {
  preselectCurrentDate: boolean
}

//
// STYLE ELEMENTS
//

export type StyleType = typeof styleTypes[number]

export type StyleConfigErrors = {
  [key in 'dependence']: boolean
}

export const initialStyleConfigErrors: StyleConfigErrors = {
  dependence: false,
}

export type StyleCore = {
  identifier: string
  visible: boolean
  type: StyleType
  dependsOn: number
  enablingValue?: Value
  configErrors: StyleConfigErrors
}

export type TextType = typeof textTypes[number]
export type TextAlignment = typeof textAlignments[number]

type TextElementSettings = {
  textType: TextType
  size: number
  alignment: TextAlignment
  content: string
}

type DividerElementSettings = {
  text: boolean
  alignment: TextAlignment
  chip: boolean
  color: MuiColor
  content: string
}

export type StyleElement = StyleCore &
  Partial<TextElementSettings> &
  Partial<DividerElementSettings>

export type TextElement = StyleCore & TextElementSettings
export type DividerElement = StyleCore & DividerElementSettings

export type LayoutState = (InputElement | StyleElement)[]