// mui
import { MenuItem, ListItemIcon, ListItemText, TextField } from '@mui/material'

// types
import { ChangeEventHandler, FC } from 'react'
import { MuiColor } from '../../types/redux/layout'

// assets
import { textFieldStyleProps } from '../../assets/helpers/mui'
import { upperCaseFirstLetter } from '../../assets/helpers/stringFormatters'

interface DropdownSelectProps {
  label: string
  value: unknown
  onChange: ChangeEventHandler<HTMLInputElement>
  iconMatches?: {
    [key: string | number]: JSX.Element
  }
  values?: string[] | number[]
  color?: MuiColor
}

const DropdownSelect: FC<DropdownSelectProps> = ({
  label,
  value,
  onChange,
  iconMatches,
  values,
  color,
}) => (
  <TextField
    {...textFieldStyleProps(label)}
    color={color || 'primary'}
    select
    value={value}
    onChange={onChange}
  >
    {iconMatches &&
      Object.keys(iconMatches).map((key) => (
        <MenuItem key={key} value={key}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <ListItemIcon>{iconMatches[key]}</ListItemIcon>
            <ListItemText>{upperCaseFirstLetter(key)}</ListItemText>
          </div>
        </MenuItem>
      ))}
    {values &&
      values.map((val) => (
        <MenuItem key={val} value={val}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <ListItemText>{val}</ListItemText>
          </div>
        </MenuItem>
      ))}
  </TextField>
)

export default DropdownSelect
