// hooks
import { createRef } from 'react'

// mui
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

// types
import { FC } from 'react'
import { SnackbarProvider } from 'notistack'
import { ReactNode } from 'react'

interface CustomizedSnackbarProviderProps {
  children: ReactNode
}

const CustomizedSnackbarProvider: FC<CustomizedSnackbarProviderProps> = ({
  children,
}) => {
  const notistackRef = createRef<SnackbarProvider>()
  const onClickDismiss = (key: string | number) => () => {
    notistackRef.current!.closeSnackbar(key)
  }
  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      hideIconVariant
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      // TransitionComponent={Slide}
      action={(key) => (
        <IconButton aria-label='dismiss' onClick={onClickDismiss(key)}>
          <Close />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export default CustomizedSnackbarProvider
