// types
import { LanguageTextDictionary } from 'src/types/languages'

// languages
import { de } from './de'
import { en } from './en'

export const textUids = [
  // alert texts
  'alertTextCommentableWorksheetCommentUid',
  'alertTextCommentableWorksheetDataSource',
  'alertTextCommentableWorksheetDataSourceLive',
  'alertTextCommentableWorksheetSiblingsUid',
  'alertTextMultipleSiblingsUids',
  'alertTextMoreMarksThanComments',
  'alertTextNonRelatedComments',
  'alertTextUsernameMissing',
  'alertTextUsernameSheetMissing',
  'alertTextConfigurationMissing',
  'alertTextFaultyServerConnection',
  // alert titles
  'alertTitleCommentableWorksheetConfig',
  'alertTitleFaultyMarkSelection',
  'alertTitleUsernameSheetMissing',
  'alertTitleConfigurationMissing',
  'alertTitleFaultyServerConnection',
  // buffers
  'bufferDatabaseCheck',
  'bufferFetchingTableauData',
  'bufferSavingConfig',
  'bufferSavingComment',
  'bufferTableauCheck',
  'bufferUsernameCheck',
  'bufferFetchingSavedFields',
  'bufferFetchingSiblings',
  // button texts
  'buttonTextAddDropdownOption',
  'buttonTextAddFormElement',
  'buttonTextAddRadio',
  'buttonTextCancel',
  'buttonTextDelete',
  'buttonTextSaveClose',
  'buttonTextDiscard',
  'buttonTextInputElement',
  'buttonTextLoad',
  'buttonTextSave',
  'buttonTextRefresh',
  'buttonTextStyleElement',
  'buttonTextThief',
  'buttonTextUpdate',
  // database table preview
  'databaseTablePreviewMetadata',
  'databaseTablePreviewInputElements',
  'databaseTablePreviewDimensions',
  // dialog titles
  'dialogTitleThief',
  // dialog contents
  'dialogContentThief',
  // dropdown options
  'dropdownOptionTextTypeHeading',
  'dropdownOptionTextTypeSubtitle',
  'dropdownOptionTextTypeParagraph',
  'dropdownOptionDirectionRow',
  'dropdownOptionDirectionCol',
  'formHelperDatabaseColMissing',
  'formHelperDatabaseColDuplicate',
  'formHelperDropdownOptionExistsError',
  'formHelperFieldRequired',
  'formHelperMissingConfigName',
  'formHelperRadioExists',
  // input element types
  'inputElementTypeRadio',
  'inputElementTypeRating',
  'inputElementTypeDatepicker',
  // input labels
  'inputLabelAddCommentText',
  'inputLabelButtonVariant',
  'inputLabelButtonArrangement',
  'inputLabelColor',
  'inputLabelCommentableWorksheets',
  'inputLabelConfigName',
  'inputLabelContent',
  'inputLabelDatabaseColumnName',
  'inputLabelDatepickerPreselectCurrentDate',
  'inputLabelDimension',
  'inputLabelDividerText',
  'inputLabelDividerChip',
  'inputLabelEditCommentText',
  'inputLabelElementDependency',
  'inputLabelElementDependencyRevealingValue',
  'inputLabelEnableCommentingOnWorksheet',
  'inputLabelFormLocation',
  'inputLabelFormInPopupDialog',
  'inputLabelFormOnDashboard',
  'inputLabelInputElementType',
  'inputLabelInputLabel',
  'inputLabelLabelPlacement',
  'inputLabelLevel',
  'inputLabelLocale',
  'inputLabelMode',
  'inputLabelMultiInputElementDirection',
  'inputLabelNewDropdownOption',
  'inputLabelRatingPrecision',
  'inputLabelRequired',
  'inputLabelRequireTableauMarkToComment',
  'inputLabelSavedConfigs',
  'inputLabelSelReq',
  'inputLabelStyleElementType',
  'inputLabelStyleElementIdentifier',
  'inputLabelTextType',
  'inputLabelTextAlignment',
  'inputLabelTextfieldMultirow',
  'inputLabelTextfieldFixedNumberOfRows',
  'inputLabelTextfieldMaximumNumberOfRows',
  'inputLabelTextfieldNumberOfRows',
  'inputLabelWorksheetDimensions',
  'inputLabelWorksheets',
  // style element types
  'styleElementTypeDivider',
  'styleElementTypeText',
  // toogle button options
  'toggleButtonOptionModeLight',
  'toggleButtonOptionModeDark',
  'toggleButtonOptionSelReq',
  'toggleButtonOptionSelNotReq',
  'optionButtonLayoutGrouped',
  'optionButtonLayoutSeparate',
  'optionButtonVariantContained',
  'optionButtonVariantOutlined',
  'optionButtonVariantText',
  // tab labels
  'tabLabelAppearance',
  'tabLabelDimensions',
  'tabLabelHelp',
  'tabLabelLayout',
  'none',
  'snackbarConfigExists',
  // snackbars
  'snackbarUsernameMissing',
  'snackbarDimensionAlreadyCommentedOn',
  'snackbarTableauObjectIdMissing',
  'paragraphEditCommentCheckMarksToEdit',
  'paragraphEditCommentNoCommentUid',
  // section header
  'sectionHeaderDashbordButtons',
  'sectionHeaderDefaultValue',
  'sectionHeaderDialogActionButtons',
  'sectionHeaderDropdownOptions',
  'sectionHeaderForm',
  'sectionHeaderFormElements',
  'sectionHeaderFundamental',
  'sectionHeaderGeneral',
  'sectionHeaderMarks',
  'sectionHeaderPreviewDatabaseTable',
  'sectionHeaderPreviewDashboardButtons',
  'sectionHeaderPreviewDialogButtons',
  'sectionHeaderPreviewForm',
  'sectionHeaderRadioButtons',
  'sectionHeaderWorksheets',
  'sectionHeaderWorksheetDimensions',
  'selected',
  'tableHeadCategory',
  'tableHeadDataType',
  'tableHeadColumnName',
  // tooltips
  'tooltipAddDropdownOption',
  'tooltipAddRadio',
  'tooltipConfigNameRequired',
  'tooltipConfigNameSavedAlready',
  'tooltipDatabaseIdentifierImmutablePostSave',
  'tooltipDeleteCommentForAllDimensions',
  'tooltipDiscardConfigChanges',
  'tooltipElementDependencyNoInputElementError',
  'tooltipElementDependencyNoOtherInputElementError',
  'tooltipFormLayoutConfigErrors',
  'tooltipNameChangeAfterSave',
  'tooltipNoInputElement',
  'tooltipNothingToSave',
  'tooltipNoChangesToDiscard',
  'tooltipInputFieldWithoutDbColName',
  'tooltipSaveCommentFormStillContainsErrors',
  'tooltipSaveCommentNoValidMarkSelected',
  'tooltipThiefCurrentLayoutAlreadySaved',
  'tooltipThiefCurrentDimensionsAlreadySaved',
  'tooltipWorksheetOptionDisabledSinceInitialized',
  'tooltipDimensionOptionDisabledSinceInitialized',
] as const

export const languages = ['de', 'en'] as const

export const dictionaryList: LanguageTextDictionary = {
  de,
  en,
}
