import { createSlice } from '@reduxjs/toolkit'
import { isNull } from 'lodash'
import { thief } from './configThunks'

// types
import { PayloadAction } from '@reduxjs/toolkit'
import { DimensionsState } from 'src/types/redux/dimensions'

// actions
import { configurationActions } from './config'

export const initialDimensions = null as DimensionsState

const dimensionsSlice = createSlice({
  name: 'dimensions',
  initialState: initialDimensions,
  reducers: {
    set: (_, action: PayloadAction<DimensionsState>) =>
      action.payload ? action.payload.slice() : null,
    toggleWorksheet: (state, action: PayloadAction<string>) => {
      if (!isNull(state)) {
        const wsIdx = state.findIndex(
          (wsDim) => wsDim.worksheet === action.payload
        )
        if (wsIdx > -1) state.splice(wsIdx, 1)
        else
          state = [
            ...state,
            {
              worksheet: action.payload,
              dimensions: [],
            },
          ]
      }
      return state
    },
    toggleWorksheetDim: (
      state,
      action: PayloadAction<{ worksheet: string; dimensions: string[] }>
    ) => {
      if (state) {
        const wsIdx = state.findIndex(
          (wsDims) => wsDims.worksheet === action.payload.worksheet
        )
        if (wsIdx > -1) state[wsIdx].dimensions = action.payload.dimensions
      }
      return state
    },
    toggleSelReq: (state) => {
      if (isNull(state)) return []
      return null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      configurationActions.set,
      (state, action) => action.payload.dimensions?.slice() || state
    )
    builder.addCase(
      configurationActions.discardChanges,
      (_, action) => action.payload.dimensions?.slice() || null
    )
    builder.addCase(thief.fulfilled, (state, action) => {
      if (action.payload.s === 'Dimensions')
        return JSON.parse(
          JSON.stringify(action.payload.state)
        ) as DimensionsState
      return state
    })
  },
})

export const dimensionsActions = dimensionsSlice.actions

export default dimensionsSlice.reducer
