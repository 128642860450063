// hooks
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  Stack,
  TextField,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  InputAdornment,
} from '@mui/material'
import { DoNotDisturb } from '@mui/icons-material'

// components
import EnablingValue from './EnablingValue'

// redux-actions
import { layoutActions } from '../../../../redux/layout'

// types
import { InputElement } from '../../../../types/redux/layout'
import { ChangeEvent, FC } from 'react'

// assets
import { textFieldStyleProps } from '../../../../assets/helpers/mui'
import { inputTypeIcons } from '../../../../assets/settings'

interface DependsOnProps {
  idx: number
}

const DependsOn: FC<DependsOnProps> = ({ idx }) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const layout = useAppSelector((state) => state.layout)
  const element = useAppSelector((state) => state.layout[idx])

  const disabled: boolean =
    'dbCol' in element
      ? layout?.filter((el) => 'dbCol' in el).length === 1
      : layout?.filter((el) => 'dbCol' in el).length === 0

  return (
    <Stack direction='column' spacing={2}>
      <Tooltip
        title={
          disabled && (
            <Typography variant='body2'>
              {'dbCol' in element
                ? texts.tooltipElementDependencyNoOtherInputElementError
                : texts.tooltipElementDependencyNoInputElementError}
            </Typography>
          )
        }
      >
        <TextField
          {...textFieldStyleProps(texts.inputLabelElementDependency)}
          disabled={disabled}
          select
          value={element.dependsOn > -1 ? element.dependsOn : -1}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            dispatch(
              layoutActions.updateDependsOn({
                idx,
                dependsOn: Number(event.target.value),
              })
            )
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                {inputTypeIcons[
                  (layout[element.dependsOn] as InputElement)?.type
                ] || <DoNotDisturb />}
              </InputAdornment>
            ),
          }}
          SelectProps={{
            renderValue: (i) => (
              <>
                {Number(i) > -1 ? (
                  (layout[i as number] as InputElement)?.dbCol
                ) : (
                  <i>{texts.none}</i>
                )}
              </>
            ),
          }}
        >
          <MenuItem value={-1}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <ListItemIcon>
                <DoNotDisturb />
              </ListItemIcon>
              <ListItemText>
                <i>{texts.none}</i>
              </ListItemText>
            </div>
          </MenuItem>
          {layout
            .map((e, i) => {
              return { e, i }
            })
            .filter(
              (d): d is { e: InputElement; i: number } =>
                'dbCol' in d.e && d.i !== idx
            )
            .map((d) => (
              <MenuItem key={d.i} value={d.i}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <ListItemIcon>{inputTypeIcons[d.e.type]}</ListItemIcon>
                  <ListItemText>{d.e.dbCol}</ListItemText>
                </div>
              </MenuItem>
            ))}
        </TextField>
      </Tooltip>
      <EnablingValue {...{ idx }} />
    </Stack>
  )
}

export default DependsOn
