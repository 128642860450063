// mui
import { Rating, Stack, Typography } from '@mui/material'
import { FC } from 'react'

// types
import { RatingElement, Value } from '../../types/redux/layout'

interface RatingInputElementProps {
  element: RatingElement
  value: Value
  onChange: any
}

const RatingInputElement: FC<RatingInputElementProps> = ({
  element,
  value,
  onChange,
}) => {
  return (
    <Stack direction='row'>
      <Stack
        direction={
          element.labelPlacement === 'top'
            ? 'column'
            : element.labelPlacement === 'end'
            ? 'row-reverse'
            : element.labelPlacement === 'bottom'
            ? 'column-reverse'
            : 'row'
        }
        spacing={1}
        alignItems={element.labelPlacement === 'top' ? 'flex-start' : 'center'}
      >
        <Typography component='legend'>{element.label || ''}</Typography>
        <Rating
          value={Number(value) > 0 ? Number(value) : null}
          onChange={onChange}
          precision={element.precision}
          size='large'
        />
      </Stack>
    </Stack>
  )
}

export default RatingInputElement
