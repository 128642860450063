import { createSlice } from '@reduxjs/toolkit'

// types
import { PayloadAction } from '@reduxjs/toolkit'
import { AppearanceState } from 'src/types/redux/appearance'
import { ConfigState } from 'src/types/redux/config'
import { DimensionsState } from 'src/types/redux/dimensions'
import { LayoutState } from 'src/types/redux/layout'

// initial states
import { initialAppearance } from './appearance'
import { initialDimensions } from './dimensions'
import { initialLayout } from './layout'

// actions
import { saveConfig, thief } from './configThunks'

export const initialConfiguration: ConfigState = {
  name: '',
  changed: false,
}

const configurationSlice = createSlice({
  name: 'setup',
  initialState: initialConfiguration,
  reducers: {
    set: (_, action: PayloadAction<ConfigState>) =>
      JSON.parse(JSON.stringify(action.payload)),
    name: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        name: action.payload,
        changed: true,
      }
    },
    selReq: (state, action: PayloadAction<boolean>) => {
      return { ...state, selReq: action.payload }
    },
    discardChanges: (_, action: PayloadAction<ConfigState>) => {
      return { ...action.payload, changed: false }
    },
    setAppearanceChanged: (state, action: PayloadAction<AppearanceState>) => {
      if (state.appearance) {
        if (
          JSON.stringify(state.appearance) === JSON.stringify(action.payload) ||
          (!state.appearance &&
            JSON.stringify(action.payload) ===
              JSON.stringify(initialAppearance))
        )
          return { ...state, changed: false }
      }
      return { ...state, changed: true }
    },
    setLayoutChanged: (state, action: PayloadAction<LayoutState>) => {
      if (
        (state.layout &&
          JSON.stringify(state.layout) === JSON.stringify(action.payload)) ||
        (!state.layout &&
          JSON.stringify(action.payload) === JSON.stringify(initialLayout))
      )
        return { ...state, changed: false }
      return { ...state, changed: true }
    },
    setDimensionsChanged: (state, action: PayloadAction<DimensionsState>) => {
      if (
        (state.dimensions &&
          JSON.stringify(state.dimensions) ===
            JSON.stringify(action.payload)) ||
        (!state.dimensions &&
          JSON.stringify(action.payload) === JSON.stringify(initialDimensions))
      )
        return { ...state, changed: false }
      return { ...state, changed: true }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveConfig.fulfilled, (state, action) => {
        const { saved, config } = action.payload
        if (saved) return JSON.parse(JSON.stringify(config))
        return state
      })
      // this is a mistake - don't change here! Only on save
      .addCase(thief.fulfilled, (state, action) => {
        if (action.payload.s === 'Dimensions')
          return { ...state, selReq: !!action.payload.state }
        return state
      })
  },
})

export const configurationActions = configurationSlice.actions

export default configurationSlice.reducer
