// hooks
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

//mui
import { DarkMode, LightMode } from '@mui/icons-material'
import {
  MuiColorInput,
  matchIsValidColor,
  MuiColorInputProps,
} from 'mui-color-input'
import {
  Stack,
  TextField,
  Button,
  Chip,
  Divider,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  ToggleButtonGroupProps,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import CommentingDialogButtons from '../../dialog/CommentingDialogButtons'

// types
import {
  AppearanceState,
  OptionsButtonVariant,
  SupportedLocales,
} from '../../../types/redux/appearance'
import { ChangeEvent, MouseEvent, FC } from 'react'

// actions
import { appearanceActions } from '../../../redux/appearance'

// assets
import { localeOptions } from '../../../redux/appearance'
import { textFieldStyleProps } from '../../../assets/helpers/mui'
import {
  optionIconsButtonLayout,
  optionIconsFormLocation,
  optionIconsButtonVariant,
} from '../../../assets/settings'
import { languages } from 'src/languages'
import {
  coorperatePrimary,
  coorperateSecondary,
  coorperateWarning,
} from '../../../redux/appearance'

const toggleButtonGroupProps: Readonly<Partial<ToggleButtonGroupProps>> = {
  size: 'small',
  exclusive: true,
}

const muiColorInputProps: Readonly<Partial<MuiColorInputProps>> = {
  size: 'small',
  format: 'hex',
  isAlphaHidden: true,
}

const AppearanceSettingsTab: FC = () => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const appearance = useAppSelector((state) => state.appearance)

  const updateAppearance = (options: Partial<AppearanceState>) =>
    dispatch(appearanceActions.update(options))

  return (
    <Grid container height='100%' width='100%'>
      <Grid xs={6} sx={{ overflowY: 'scroll' }} height='100%'>
        <Stack direction='column' spacing={5} padding={2}>
          <div>
            <Divider textAlign='left'>
              <Chip
                label={texts.sectionHeaderGeneral}
                color='default'
                sx={{
                  alignSelf: 'center',
                  justifySelf: 'center',
                  borderRadius: 1,
                }}
              />
            </Divider>
          </div>
          <Stack direction='column' spacing={2}>
            <Stack direction='row' spacing={4}>
              <Stack direction='column' spacing={1}>
                <Typography>{texts.inputLabelMode}</Typography>
                <ToggleButtonGroup
                  {...{
                    ...toggleButtonGroupProps,
                    value: !!appearance.darkMode,
                    onChange: (_: MouseEvent<HTMLElement>, darkMode: boolean) =>
                      darkMode !== null &&
                      dispatch(updateAppearance({ darkMode })),
                  }}
                >
                  <ToggleButton value={false}>
                    <LightMode sx={{ marginRight: 1 }} />
                    {texts.toggleButtonOptionModeLight}
                  </ToggleButton>
                  <ToggleButton value={true}>
                    <DarkMode sx={{ marginRight: 1 }} />
                    {texts.toggleButtonOptionModeDark}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <Stack direction='column' spacing={1}>
                <Typography>{texts.inputLabelLocale}</Typography>
                <ToggleButtonGroup
                  {...{
                    ...toggleButtonGroupProps,
                    value: appearance.locale || 'enUS',
                    onChange: (
                      _: MouseEvent<HTMLElement>,
                      locale: SupportedLocales
                    ) =>
                      locale !== null && dispatch(updateAppearance({ locale })),
                  }}
                >
                  {localeOptions
                    .filter(
                      (l) =>
                        languages
                          .map((l) => l as string)
                          .indexOf((l as string).slice(0, 2)) > -1
                    )
                    .map((lan, idx) => (
                      <ToggleButton key={idx} value={lan}>
                        {(lan as string).slice(0, 2)}
                      </ToggleButton>
                    ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>

            <Stack direction='column' spacing={1}>
              <Typography>{texts.inputLabelFormLocation}</Typography>
              <ToggleButtonGroup
                {...{
                  ...toggleButtonGroupProps,
                  value: !!appearance.popupDialog,
                  onChange: (
                    _: MouseEvent<HTMLElement>,
                    popupDialog: boolean
                  ) =>
                    popupDialog !== null &&
                    dispatch(updateAppearance({ popupDialog })),
                }}
              >
                {[false, true].map((popup, idx) => (
                  <ToggleButton key={idx} value={popup}>
                    {optionIconsFormLocation(popup)}
                    {popup
                      ? texts.inputLabelFormInPopupDialog
                      : texts.inputLabelFormOnDashboard}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
          </Stack>

          {appearance.popupDialog && (
            <>
              <div>
                <Divider textAlign='left'>
                  <Chip
                    label={texts.sectionHeaderDashbordButtons}
                    color='default'
                    sx={{
                      alignSelf: 'center',
                      justifySelf: 'center',
                      borderRadius: 1,
                    }}
                  />
                </Divider>
              </div>
              <Stack direction='column' spacing={2}>
                <Stack direction='row' spacing={4}>
                  <Stack direction='column' spacing={1}>
                    <Typography>{texts.inputLabelButtonArrangement}</Typography>
                    <ToggleButtonGroup
                      {...{
                        ...toggleButtonGroupProps,
                        value: !!appearance.dialogButtonsGrouped,
                        onChange: (
                          _: MouseEvent<HTMLElement>,
                          dialogButtonsGrouped: boolean
                        ) =>
                          dialogButtonsGrouped !== null &&
                          dispatch(updateAppearance({ dialogButtonsGrouped })),
                      }}
                    >
                      {[false, true].map((grpd, idx) => (
                        <ToggleButton key={idx} value={grpd}>
                          {optionIconsButtonLayout(grpd)}
                          {grpd
                            ? texts.optionButtonLayoutGrouped
                            : texts.optionButtonLayoutSeparate}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Stack>
                  <Stack direction='column' spacing={1}>
                    <Typography>{texts.inputLabelButtonVariant}</Typography>
                    <ToggleButtonGroup
                      {...{
                        ...toggleButtonGroupProps,
                        value: appearance.dashboardButtonVariant,
                        onChange: (
                          _: MouseEvent<HTMLElement>,
                          dashboardButtonVariant: typeof appearance.dashboardButtonVariant
                        ) =>
                          dispatch(
                            updateAppearance({ dashboardButtonVariant })
                          ),
                      }}
                    >
                      {Object.values(OptionsButtonVariant).map(
                        (variant, idx) => (
                          <ToggleButton key={idx} value={variant}>
                            {optionIconsButtonVariant(variant)}
                            {variant === OptionsButtonVariant.contained
                              ? texts.optionButtonVariantContained
                              : variant === OptionsButtonVariant.outlined
                              ? texts.optionButtonVariantOutlined
                              : texts.optionButtonVariantText}
                          </ToggleButton>
                        )
                      )}
                    </ToggleButtonGroup>
                  </Stack>
                </Stack>

                <Stack direction='row' spacing={2} paddingTop={1}>
                  <TextField
                    {...textFieldStyleProps(texts.inputLabelAddCommentText)}
                    value={appearance.addCommentText}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      updateAppearance({ addCommentText: event.target.value })
                    }
                  />
                  <MuiColorInput
                    {...{
                      ...muiColorInputProps,
                      label: texts.inputLabelColor,
                      fallbackValue: coorperatePrimary,
                      value: appearance.addCommentColor,
                      onChange: (addCommentColor) => {
                        matchIsValidColor(addCommentColor) &&
                          updateAppearance({ addCommentColor })
                      },
                    }}
                  />
                </Stack>
                <Stack
                  direction='row'
                  spacing={2}
                  sx={{ paddingBottom: 2 }}
                  alignItems='center'
                >
                  <TextField
                    {...textFieldStyleProps(texts.inputLabelEditCommentText)}
                    value={appearance.editCommentText}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      updateAppearance({ editCommentText: event.target.value })
                    }
                  />
                  <MuiColorInput
                    {...{
                      ...muiColorInputProps,
                      label: texts.inputLabelColor,
                      fallbackValue: coorperateSecondary,
                      value: appearance.editCommentColor,
                      onChange: (editCommentColor) => {
                        matchIsValidColor(editCommentColor) &&
                          updateAppearance({ editCommentColor })
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </>
          )}

          <div>
            <Divider textAlign='left'>
              <Chip
                label={texts.sectionHeaderDialogActionButtons}
                color='default'
                sx={{
                  alignSelf: 'center',
                  justifySelf: 'center',
                  borderRadius: 1,
                }}
              />
            </Divider>
          </div>

          <Stack direction='column' spacing={2}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Stack direction='column' spacing={1}>
                <Typography>{texts.inputLabelButtonArrangement}</Typography>
                <ToggleButtonGroup
                  {...{
                    ...toggleButtonGroupProps,
                    value: appearance.dialogButtonsVariant,
                    onChange: (
                      _: MouseEvent<HTMLElement>,
                      dialogButtonsVariant: typeof appearance.dialogButtonsVariant
                    ) => dispatch(updateAppearance({ dialogButtonsVariant })),
                  }}
                >
                  {Object.values(OptionsButtonVariant).map((variant, idx) => (
                    <ToggleButton key={idx} value={variant}>
                      {optionIconsButtonVariant(variant)}
                      {variant === OptionsButtonVariant.contained
                        ? texts.optionButtonVariantContained
                        : variant === OptionsButtonVariant.outlined
                        ? texts.optionButtonVariantOutlined
                        : texts.optionButtonVariantText}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </Stack>
            <Stack direction='row' spacing={2}>
              <MuiColorInput
                {...{
                  ...muiColorInputProps,
                  label: texts.buttonTextCancel,
                  fallbackValue: coorperateWarning,
                  value: appearance.dialogCancelColor,
                  onChange: (dialogCancelColor) => {
                    matchIsValidColor(dialogCancelColor) &&
                      updateAppearance({ dialogCancelColor })
                  },
                }}
              />
              <MuiColorInput
                {...{
                  ...muiColorInputProps,
                  label: texts.buttonTextSave,
                  fallbackValue: coorperateSecondary,
                  value: appearance.dialogSaveColor,
                  onChange: (dialogSaveColor) => {
                    matchIsValidColor(dialogSaveColor) &&
                      updateAppearance({ dialogSaveColor })
                  },
                }}
              />
              <MuiColorInput
                {...{
                  ...muiColorInputProps,
                  label: texts.buttonTextRefresh,
                  fallbackValue: coorperatePrimary,
                  value: appearance.dialogRefreshColor,
                  onChange: (dialogRefreshColor) => {
                    matchIsValidColor(dialogRefreshColor) &&
                      updateAppearance({ dialogRefreshColor })
                  },
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Grid>

      <Grid xs={6} sx={{ overflowY: 'scroll' }} height='100%'>
        <Stack direction='column' spacing={5} padding={2}>
          {appearance.popupDialog && (
            <>
              <div>
                <Divider textAlign='left'>
                  <Chip
                    label={texts.sectionHeaderPreviewDashboardButtons}
                    color='default'
                    sx={{ borderRadius: 1 }}
                  />
                </Divider>
              </div>
              <Stack
                direction='row'
                spacing={2}
                alignSelf='center'
                alignItems='stretch'
              >
                <Button
                  sx={{ textTransform: 'none' }}
                  color='add'
                  variant={appearance.dashboardButtonVariant}
                >
                  {appearance.addCommentText}
                </Button>
                <Button
                  sx={{ textTransform: 'none' }}
                  color='edit'
                  variant={appearance.dashboardButtonVariant}
                >
                  {appearance.editCommentText}
                </Button>
              </Stack>
            </>
          )}

          <div>
            <Divider textAlign='left'>
              <Chip
                label={texts.sectionHeaderPreviewDialogButtons}
                color='default'
                sx={{
                  alignSelf: 'center',
                  justifySelf: 'center',
                  borderRadius: 1,
                }}
              />
            </Divider>
          </div>
          <Stack direction='column' spacing={2} alignItems='center'>
            <Stack direction='row' spacing={2}>
              <CommentingDialogButtons
                {...{
                  edit: false,
                  handleSave: () => new Promise(() => {}),
                  preview: true,
                }}
              />
            </Stack>
            <Stack direction='row' spacing={2}>
              <CommentingDialogButtons
                {...{
                  edit: true,
                  handleSave: () => new Promise(() => {}),
                  preview: true,
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default AppearanceSettingsTab
