// hooks
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useState } from 'react'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  Divider,
  Chip,
  Menu,
  MenuItem,
  alpha,
  MenuProps,
  styled,
  Button,
  Accordion,
  AccordionDetails,
  Stack,
  ButtonGroup,
} from '@mui/material'
import {
  Brush,
  Create,
  Delete,
  KeyboardArrowDown,
  Save,
} from '@mui/icons-material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import LayoutView from '../../form'
import InputElementSettings from './inputElementSettings'
import StyleElementSettings from './styleElementSettings'
import ElementAccordionSummary from './ElementAccordionSummary'

// redux-actions
import { layoutActions } from '../../../redux/layout'

// types
import { SyntheticEvent, FC } from 'react'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

const LayoutSettings: FC = () => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const configLayout = useAppSelector((state) => state.config.layout)
  const layout = useAppSelector((state) => state.layout)

  const [expanded, setExpanded] = useState<number>(-1)

  // add element button
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <Grid container height='100%' width='100%'>
      <Grid xs={6} sx={{ overflowY: 'scroll' }} height='100%'>
        <Stack direction='column' spacing={2} padding={2}>
          <div>
            <Divider textAlign='left'>
              <Chip
                label={texts.sectionHeaderFormElements}
                color='default'
                sx={{
                  alignSelf: 'center',
                  justifySelf: 'center',
                  borderRadius: 1,
                }}
              />
            </Divider>
          </div>
          {layout.length > 0 && (
            <Stack direction='column'>
              {layout.map((element, idx) => {
                const accordionProps = {
                  key: idx,
                  idx,
                  expanded,
                  setExpanded,
                }
                const isInputElement = 'dbCol' in element
                const inputElementMissingDbCol =
                  isInputElement && !element.dbCol
                return (
                  <Accordion
                    key={idx}
                    expanded={expanded === idx}
                    sx={{
                      border: inputElementMissingDbCol
                        ? 1
                        : expanded === idx
                        ? 1
                        : 0,
                      borderColor: isInputElement
                        ? inputElementMissingDbCol
                          ? 'error.main'
                          : 'primary.main'
                        : 'secondary.main',
                    }}
                    onChange={(_: SyntheticEvent<Element, Event>) =>
                      setExpanded(idx === expanded ? -1 : idx)
                    }
                  >
                    <ElementAccordionSummary {...{ idx }} />
                    <AccordionDetails>
                      {'dbCol' in element ? (
                        <InputElementSettings {...accordionProps} />
                      ) : (
                        <StyleElementSettings {...accordionProps} />
                      )}

                      <Stack marginRight={2}>
                        <ButtonGroup sx={{ alignSelf: 'flex-end' }}>
                          <Button
                            variant='outlined'
                            color='error'
                            endIcon={<Delete />}
                            disabled={
                              'dbCol' in element &&
                              configLayout &&
                              configLayout.findIndex(
                                (e) => 'dbCol' in e && e.dbCol === element.dbCol
                              ) > -1
                            }
                            onClick={() =>
                              dispatch(layoutActions.deleteElement(idx))
                            }
                          >
                            {texts.buttonTextDelete}
                          </Button>
                          <Button
                            variant='outlined'
                            color='success'
                            endIcon={<Save />}
                            onClick={() => setExpanded(-1)}
                          >
                            {texts.buttonTextSaveClose}
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Stack>
          )}
          <div style={{ alignSelf: 'center' }}>
            <Button
              // startIcon={<Add />}
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              variant='contained'
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDown />}
            >
              {texts.buttonTextAddFormElement}
            </Button>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  setExpanded(-1)
                  dispatch(layoutActions.addStyle())
                  handleClose()
                }}
                disableRipple
              >
                <Brush />
                {texts.buttonTextStyleElement}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setExpanded(-1)
                  dispatch(layoutActions.addInput())
                  handleClose()
                }}
                disableRipple
              >
                <Create />
                {texts.buttonTextInputElement}
              </MenuItem>
            </StyledMenu>
          </div>
        </Stack>
      </Grid>
      <Grid xs={6} sx={{ overflowY: 'scroll' }} height='100%'>
        <Stack direction='column' spacing={2} padding={2}>
          <div>
            <Divider textAlign='left'>
              <Chip
                label={texts.sectionHeaderPreviewForm}
                color='default'
                sx={{ borderRadius: 1 }}
              />
            </Divider>
          </div>
          <LayoutView preview={true} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default LayoutSettings
