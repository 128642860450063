import { createAsyncThunk } from '@reduxjs/toolkit'

import api from '../api'

// types
import { AppearanceState } from '../types/redux/appearance'
import { DimensionsState } from '../types/redux/dimensions'
import { LayoutState } from '../types/redux/layout'
import { ConfigState } from '../types/redux/config'

// default values
import { defaultValue } from './layout'

import { STabs } from '../components/settings'
import packageJSON from '../../package.json'

const { version } = packageJSON

const resetLayoutVals = (layout: LayoutState) =>
  layout.map((e) => {
    if ('dbCol' in e) return { ...e, value: defaultValue(e.type) }
    return e
  })

export const saveConfig = createAsyncThunk<
  {
    saved: boolean
    charactersExceeded: boolean
    duplicateName?: boolean
    config: ConfigState
  },
  void,
  {
    state: {
      config: ConfigState
      appearance: AppearanceState
      layout: LayoutState
      dimensions: DimensionsState
    }
  }
>('configs/save', async (_, { getState }) => {
  const { config, appearance, layout, dimensions } = getState()
  const response: {
    saved: boolean
    charactersExceeded: boolean
    duplicateName: boolean
    config: ConfigState
  } = await api
    .post(`configs/${config.appearance ? 'update' : 'publish'}`, {
      configName: config.name,
      version,
      appearance:
        JSON.stringify(appearance) !==
          JSON.stringify(config.appearance) && appearance,
      layout:
        JSON.stringify(layout) !== JSON.stringify(config.layout) &&
        resetLayoutVals(layout),
      dimensions:
        JSON.stringify(dimensions) !==
          JSON.stringify(config.dimensions) && dimensions,
    })
    .then((response) => {
      return {
        saved: response.data.saved as boolean,
        charactersExceeded: response.data.characteresExceeded as boolean,
        duplicateName: response.data.duplicateName as boolean,
        config: {
          ...config,
          appearance,
          layout: resetLayoutVals(layout),
          dimensions,
          changed: false,
        },
      }
    })
  return response
})

export const loadConfigFromTableau = createAsyncThunk<
  ConfigState | undefined,
  string,
  {}
>('configs/get', async (tableauObjectId) => {
  const configJSON = tableau.extensions.settings.get(tableauObjectId)
  if (configJSON) return JSON.parse(configJSON)
  return configJSON
})

export const loadConfigs = createAsyncThunk<string[]>(
  'configs',
  async () => {
    const response = await api.get('/configs').then((response) => response)
    return response.data.configs
  }
)

export const thief = createAsyncThunk<
  { s: STabs; state: AppearanceState | LayoutState | DimensionsState },
  { configName: string; s: STabs },
  {}
>(
  'configs/thief',
  async ({ configName, s }) =>
    await api
      .get(
        `configs/thief?s=${s}&config-name=${configName}`
      )
      .then((response) => {
        return {
          s,
          state: JSON.parse(response.data.state) as
            | AppearanceState
            | LayoutState
            | DimensionsState,
        }
      })
)
