// hooks
import { useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  Stack,
  TextField,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'

// types
import { ChangeEvent, FC, MouseEvent } from 'react'
import {
  StyleElement,
  TextElement,
  TextType,
} from '../../../../types/redux/layout'

// assets
import { textFieldStyleProps } from '../../../../assets/helpers/mui'
import { textTypes } from '../../../../assets/settings'
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
} from '@mui/icons-material'

interface TextElementSettingsProps {
  idx: number
  handleUpdateStyle: (options: Partial<StyleElement>) => {
    payload: {
      idx: number
      options: Partial<StyleElement>
    }
    type: string
  }
}

const TextElementSettings: FC<TextElementSettingsProps> = ({
  idx,
  handleUpdateStyle,
}) => {
  const texts = useLanguage()

  const element = useAppSelector((state) => state.layout[idx] as TextElement)

  const getTextType = (textType: 'heading' | 'subtitle' | 'body') => {
    switch (textType) {
      case 'heading':
        return texts.dropdownOptionTextTypeHeading
      case 'subtitle':
        return texts.dropdownOptionTextTypeSubtitle
      case 'body':
        return texts.dropdownOptionTextTypeParagraph
    }
  }

  return (
    <Stack direction='column' spacing={2}>
      <Stack direction='row' spacing={2} width='stretch'>
        <TextField
          {...textFieldStyleProps(texts.inputLabelTextType)}
          color='secondary'
          select
          value={element.textType}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateStyle({
              textType: event.target.value as TextType,
              size: 1,
            })
          }
        >
          {textTypes.map((textType) => (
            <MenuItem key={textType} value={textType}>
              {getTextType(textType)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          {...textFieldStyleProps(texts.inputLabelLevel)}
          color='secondary'
          disabled={!element.textType}
          select
          value={element.size}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateStyle({ size: Number(event.target.value) })
          }
        >
          {(element.textType === 'heading' ? [1, 2, 3, 4, 5, 6] : [1, 2]).map(
            (s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            )
          )}
        </TextField>
        <ToggleButtonGroup
          size='small'
          value={element.alignment}
          exclusive
          onChange={(
            _: MouseEvent<HTMLElement>,
            alignment: typeof element.alignment
          ) => alignment !== null && handleUpdateStyle({ alignment })}
        >
          <ToggleButton value={'left' as typeof element.alignment}>
            <FormatAlignLeft />
          </ToggleButton>
          <ToggleButton value={'center' as typeof element.alignment}>
            <FormatAlignCenter />
          </ToggleButton>
          <ToggleButton value={'right' as typeof element.alignment}>
            <FormatAlignRight />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <TextField
        {...textFieldStyleProps(texts.inputLabelContent)}
        color='secondary'
        multiline
        maxRows={8}
        value={element.content}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleUpdateStyle({ content: event.target.value })
        }
      />
    </Stack>
  )
}

export default TextElementSettings
