import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api'

// assets
import { isNull } from 'lodash'
import {
  findColIdx,
  findRow,
  findRows,
  getCbDataTable,
  getValFromRow,
} from '../assets/helpers/tableau'

// types
import { MarkSelection } from '../types/marks'
import { InputElement, LayoutState, Value } from './../types/redux/layout'
import { ConfigState } from '../types/redux/config'
import { DimensionsState } from './../types/redux/dimensions'

export const saveComment = createAsyncThunk<
  { success: boolean; duplicateComment: boolean },
  { username: string; markSelection: MarkSelection; commentUids?: number[] },
  {
    state: {
      config: ConfigState
      layout: LayoutState
      dimensions: DimensionsState
    }
  }
>(
  'comment/add',
  async ({ username, markSelection, commentUids }, { getState }) => {
    const { layout, config, dimensions } = getState()
    return await api
      .put(
        `comment/${commentUids && commentUids.length > 0 ? 'edit' : 'add'}`,
        {
          username,
          configName: config.name,
          fields: layout
            .filter((element): element is InputElement => 'dbCol' in element)
            .map((element) => {
              return { dbCol: element.dbCol, value: element.value }
            }),
          selReq: !isNull(dimensions),
          markSelection,
          commentUids,
        }
      )
      .then((response) => response.data)
  }
)

export const getSavedFields = createAsyncThunk<
  { layout: LayoutState; siblingsUid?: number },
  { commentUid: number; worksheet: string },
  { state: { config: ConfigState; layout: LayoutState } }
>('comment/get-fields', async ({ commentUid, worksheet }, { getState }) => {
  let { config, layout } = getState()
  return getCbDataTable({ configName: config.name, worksheet }).then(
    (dataTable) => {
      if (dataTable) {
        const commentRow = findRow({
          dataTable,
          key: 'Comment Uid',
          val: commentUid,
        })
        const siblingsUidColIdx = findColIdx({ dataTable, key: 'Siblings Uid' })
        if (commentRow)
          return {
            layout: layout.map((element) => {
              if ('dbCol' in element) {
                let inputElement = JSON.parse(
                  JSON.stringify(element)
                ) as InputElement
                const dbColIdx = findColIdx({ dataTable, key: element.dbCol })
                if (dbColIdx > -1 && commentRow)
                  inputElement.value = commentRow[dbColIdx].nativeValue
                if (inputElement.required)
                  inputElement.inputErrors.required =
                    inputElement.type === 'Checkbox' ||
                    inputElement.type === 'Switch'
                      ? !inputElement.value
                      : !!inputElement.value
                return inputElement
              }
              return element
            }),
            siblingsUid: siblingsUidColIdx
              ? getValFromRow({
                  row: commentRow,
                  colIdx: siblingsUidColIdx,
                })
              : undefined,
          }
      }
      return { layout }
    }
  )
})

export const getSiblings = createAsyncThunk<
  { [key: string]: Value; commentUid: number }[],
  { siblingsUid: number; worksheet: string },
  { state: { config: ConfigState } }
>('comment/get-siblings', async ({ siblingsUid, worksheet }, { getState }) => {
  const { config } = getState()
  const dimensions =
    config.dimensions?.find((wsDims) => wsDims.worksheet === worksheet)
      ?.dimensions || ([] as string[])
  return getCbDataTable({ configName: config.name, worksheet }).then(
    (dataTable) => {
      if (dataTable) {
        const commentRows = findRows({
          dataTable,
          key: 'Siblings Uid',
          val: siblingsUid,
        })
        if (commentRows) {
          const commentUidColIdx = findColIdx({ dataTable, key: 'Comment Uid' })
          const dimensionsColIdc = dimensions.map((dimension) =>
            findColIdx({ dataTable, key: dimension })
          )
          if (commentUidColIdx > -1 && dimensionsColIdc) {
            return commentRows.map((commentRow) => {
              let comment = {
                commentUid: commentRow[commentUidColIdx].nativeValue as number,
              } as {
                [key: string]: Value
                commentUid: number
              }
              dimensions.forEach(
                (key, idx) =>
                  (comment[key] =
                    commentRow[dimensionsColIdc[idx] as number]?.nativeValue ||
                    null)
              )
              return comment
            })
          }
        }
      }
      return [] as { [key: string]: Value; commentUid: number }[]
    }
  )
})

export const deleteComments = createAsyncThunk<
  boolean,
  number[],
  { state: { config: ConfigState } }
>('comment/delete', async (commentUids, { getState }) => {
  const { config } = getState()
  return await api
    .post('comment/delete', { commentUids, configName: config.name })
    .then((response) => response.data.success)
})
