// hooks
import { useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import { Stack, Divider, Chip } from '@mui/material'

// components
import DropdownSelect from '../../DropdownSelect'
import CheckboxSwitch from '../../../form/CheckboxSwitch'

// types
import { ChangeEvent, FC } from 'react'
import {
  CheckboxSwitchElement,
  InputElement,
  LabelPlacement,
  MuiColor,
  RadiogroupElement,
} from '../../../../types/redux/layout'

// assets
import { labelPlacementIcons, muiColorIcons } from '../../../../assets/settings'

interface CheckboxSwitchSettingsProps {
  idx: number
  handleUpdateInput: (options: Partial<InputElement>) => {
    payload: {
      idx: number
      options: Partial<InputElement>
    }
    type: string
  }
}

const CheckboxSwitchSettings: FC<CheckboxSwitchSettingsProps> = ({
  idx,
  handleUpdateInput,
}) => {
  const texts = useLanguage()

  const element = useAppSelector(
    (state) => state.layout[idx] as RadiogroupElement
  )

  return (
    <Stack key={idx} direction='column' spacing={3}>
      <Stack direction='row' spacing={2}>
        <DropdownSelect
          {...{
            label: texts.inputLabelLabelPlacement,
            iconMatches: labelPlacementIcons,
            value: element.labelPlacement,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              handleUpdateInput({
                labelPlacement: event.target.value as LabelPlacement,
              }),
          }}
        />
      </Stack>
      <Stack direction='row' spacing={2}>
        <DropdownSelect
          {...{
            label: texts.inputLabelColor,
            iconMatches: muiColorIcons,
            value: element.color,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              handleUpdateInput({
                color: event.target.value as MuiColor,
              }),
          }}
        />
      </Stack>
      <Divider textAlign='left'>
        <Chip
          label={texts.sectionHeaderDefaultValue}
          color='default'
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            borderRadius: 1,
          }}
        />
      </Divider>
      <CheckboxSwitch
        {...{
          element: element as CheckboxSwitchElement,
          value: element.default,
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateInput({ default: event.target.checked }),
        }}
      />
    </Stack>
  )
}

export default CheckboxSwitchSettings
