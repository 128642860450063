import { KeyboardEvent } from 'react'

export const maxTableNameLength = () => {
  const prefixLength = Math.max('cb_comments_'.length, 'cb_history_'.length)
  const dbTableNameLenLim: number = 128
  return dbTableNameLenLim - prefixLength
}

export const restrictTableNameChars = (
  event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const asciiCode = event.key.charCodeAt(0)
  if (asciiCode < 32 || asciiCode > 126) event.preventDefault()
}

export const maxColumnNameLength = () => 128

export const restrictColumnNameChars = (
  event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const asciiCode = event.key.charCodeAt(0)
  if (asciiCode < 32 || asciiCode > 126) event.preventDefault()
}

export const filterColumnNameChars = (columnName: string) => {
  let prohibitedChars: string[] = []
  for (let char of columnName) {
    const asciiCode = char.charCodeAt(0)
    if (asciiCode < 32 || asciiCode > 126) prohibitedChars.push(char)
  }
  let filteredColName = columnName
  for (let char in prohibitedChars) {
    filteredColName.replace(char, '')
  }
  return filteredColName
}
