// hooks
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import { Stack, Divider, AccordionDetails, TextField } from '@mui/material'

// components
import TextElementSettings from './TextElement'
import DividerElementSettings from './DividerElement'
import DependsOn from '../dependsOn'
import DropdownSelect from '../../DropdownSelect'

// types
import { ChangeEvent, FC } from 'react'
import { StyleElement, StyleType } from '../../../../types/redux/layout'

// redux-actions
import { layoutActions } from '../../../../redux/layout'

// assets
import { textFieldStyleProps } from '../../../../assets/helpers/mui'
import { styleTypeIcons } from '../../../../assets/settings'

interface StyleElementSettingsProps {
  idx: number
}

const StyleElementSettings: FC<StyleElementSettingsProps> = ({ idx }) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const element = useAppSelector((state) => state.layout[idx] as StyleElement)

  const handleUpdateStyle = (options: Partial<StyleElement>) =>
    dispatch(layoutActions.updateStyle({ idx, options }))

  const handleChangeStyleType = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      layoutActions.updateStyleType({
        idx,
        type: event.target.value as StyleType,
      })
    )

  const props = { idx, handleUpdateStyle }
  let settings: JSX.Element
  switch (element.type) {
    case 'Text':
      settings = <TextElementSettings {...props} />
      break
    case 'Divider':
      settings = <DividerElementSettings {...props} />
      break
  }

  return (
    <AccordionDetails>
      <Stack direction='column' width='stretch' spacing={2}>
        <Stack direction='row' spacing={2}>
          <DropdownSelect
            {...{
              label: texts.inputLabelStyleElementType,
              iconMatches: styleTypeIcons,
              value: element.type,
              onChange: handleChangeStyleType,
              color: 'secondary',
            }}
          />
          <TextField
            {...textFieldStyleProps(texts.inputLabelStyleElementIdentifier)}
            color='secondary'
            value={element.identifier}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleUpdateStyle({ identifier: event.target.value })
            }
          />
        </Stack>

        <Divider />

        {settings}

        <Divider />

        <DependsOn
          {...{
            idx,
            handleUpdateElement: handleUpdateStyle,
          }}
        />
      </Stack>
    </AccordionDetails>
  )
}

export default StyleElementSettings
