import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// types
import { TableauState } from 'src/types/redux/tableau'
import { checkTableau, checkUsername, checkDatabase } from './tableauThunks'

export const initialTableau = {
  fullyInitialized: false,
  username: { sheetExists: false },
  worksheets: {},
  databaseInitialized: false,
} as TableauState

const checkFullInitialization = (tableau: TableauState) => {
  let fullyInitialized = false
  if (!tableau.databaseInitialized) return fullyInitialized
  if (tableau.username.sheetExists && tableau.username.name)
    fullyInitialized = true
  const worksheets = Object.values(tableau.worksheets)
  if (worksheets.length > 0) {
    if (
      worksheets
        .map((worksheetInfo) => Object.values(worksheetInfo).every((b) => b))
        .every((b) => b)
    )
      fullyInitialized = true
    else fullyInitialized = false
  }

  return fullyInitialized
}

const tableauSlice = createSlice({
  name: 'tableau',
  initialState: initialTableau,
  reducers: {
    set: (_, action: PayloadAction<TableauState>) => {
      return { ...action.payload }
    },
  },
  extraReducers(builder) {
    builder.addCase(checkTableau.fulfilled, (state, action) => {
      const worksheets = action.payload
      return {
        ...state,
        worksheets,
        fullyInitialized: checkFullInitialization({
          ...state,
          worksheets,
        }),
      }
    })
    builder.addCase(checkUsername.fulfilled, (state, action) => {
      const username = action.payload
      return {
        ...state,
        username,
        fullyInitialized: checkFullInitialization({
          ...state,
          username,
        }),
      }
    })
    builder.addCase(checkDatabase.fulfilled, (state, action) => {
      const databaseInitialized = action.payload.success
      return {
        ...state,
        databaseInitialized,
        fullyInitialized: checkFullInitialization({
          ...state,
          databaseInitialized,
        }),
      }
    })
  },
})

export const tableauActions = tableauSlice.actions

export default tableauSlice.reducer
