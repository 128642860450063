// hooks
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useState, useMemo } from 'react'
import { useLanguage } from 'src/hooks/languages'

// components
import WorksheetAccordionSummary from './WorksheetAccordionSummary'
import WorksheetAccordionDetails from './WorksheetAccordionDetails'
import DimensionsPreview from './DimensionsPreview'

// mui
import {
  Stack,
  Chip,
  Divider,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Accordion,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// types
import { FC, SyntheticEvent } from 'react'
import { Worksheet } from '@tableau/extensions-api-types'
import { InputElement } from '../../../types/redux/layout'
import { MouseEvent } from 'react'

// assets
import { createPossibleTableauDimensions } from '../../../assets/helpers/tableau'

// other
import { dimensionsActions } from 'src/redux/dimensions'

interface DimensionSettingsProps {
  availableSheets: string[]
}

const DimensionSettings: FC<DimensionSettingsProps> = ({ availableSheets }) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const layout = useAppSelector((state) => state.layout)
  const selReq = useAppSelector((state) => !!state.dimensions)

  const [worksheetExpanded, setWorksheetExpanded] = useState(-1)

  const avWsDims: { [key: string]: string[] } = useMemo(() => {
    let avWsDimsPrel: { [key: string]: string[] } = {}
    availableSheets.forEach((worksheet) => {
      tableau.extensions.dashboardContent?.dashboard.worksheets
        .find((tWs: Worksheet) => tWs.name === worksheet)
        ?.getSummaryDataAsync()
        .then((dt) => {
          const possibleTableauDimensions = [
            ...layout
              .filter((e): e is InputElement => 'dbCol' in e)
              .map((e) => createPossibleTableauDimensions(e.dbCol)),
            createPossibleTableauDimensions('Comment Uid'),
          ].flat()

          avWsDimsPrel[worksheet] = dt.columns
            .map((col) => col.fieldName)
            .filter((dim) => possibleTableauDimensions.indexOf(dim) === -1)
        })
    })
    return avWsDimsPrel
  }, [availableSheets, layout])

  return (
    <Grid container height='100%' width='100%'>
      <Grid xs={6} sx={{ overflowY: 'scroll' }} height='100%'>
        <Stack direction='column' spacing={2} padding={2}>
          <Stack direction='column' spacing={1}>
            <Typography>{texts.inputLabelSelReq}</Typography>
            <ToggleButtonGroup
              value={selReq}
              exclusive
              onChange={(_: MouseEvent<HTMLElement>, selReq: boolean) =>
                selReq !== null && dispatch(dimensionsActions.toggleSelReq())
              }
            >
              <ToggleButton value={false}>
                {texts.toggleButtonOptionSelNotReq}
              </ToggleButton>
              <ToggleButton value={true}>
                {texts.toggleButtonOptionSelReq}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          {selReq && (
            <Stack direction='column' spacing={1}>
              <Typography>{texts.inputLabelWorksheets}</Typography>
              <Stack direction='column'>
                {availableSheets.map((worksheet, wsIdx) => (
                  <Accordion
                    key={wsIdx}
                    expanded={worksheetExpanded === wsIdx}
                    sx={{
                      border: worksheetExpanded === wsIdx ? 1 : 0,
                      borderColor: 'primary.main',
                    }}
                    onChange={(_: SyntheticEvent<Element, Event>) =>
                      setWorksheetExpanded(
                        wsIdx === worksheetExpanded ? -1 : wsIdx
                      )
                    }
                  >
                    <WorksheetAccordionSummary {...{ idx: wsIdx, worksheet }} />
                    <WorksheetAccordionDetails
                      {...{
                        worksheet,
                        availableWorksheetDimensions: avWsDims[worksheet],
                      }}
                    />
                  </Accordion>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid xs={6} sx={{ overflowY: 'scroll' }} height='100%'>
        <Stack direction='column' spacing={2} padding={2}>
          <div>
            <Divider textAlign='left'>
              <Chip
                label={texts.sectionHeaderPreviewDatabaseTable}
                color='default'
                sx={{ borderRadius: 1 }}
              />
            </Divider>
          </div>
          <DimensionsPreview />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default DimensionSettings
