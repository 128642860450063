// hooks
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { useMemo } from 'react'
import { useLanguage } from 'src/hooks/languages'

// mui
import { Save } from '@mui/icons-material'
import { Tooltip, Typography, Button } from '@mui/material'

// redux-thunks
import { saveConfig } from 'src/redux/configThunks'

// redux-actions
import { snackbarsActions } from 'src/redux/snackbars'

const ConfigSaveButton = () => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const existingConfig = useAppSelector((state) => !!state.config.appearance)
  const configUnchanged = useAppSelector((state) => !state.config.changed)
  const configNameMissing = useAppSelector((state) => !state.config.name)
  const noInputElement = useAppSelector(
    (state) => state.layout.filter((e) => 'dbCol' in e).length === 0
  )
  const inputElementMissingColName = useAppSelector(
    (state) => state.layout.filter((e) => 'dbCol' in e && !e.dbCol).length > 0
  )

  const disabled = useMemo(
    () =>
      configUnchanged ||
      configNameMissing ||
      noInputElement ||
      inputElementMissingColName,
    [
      configUnchanged,
      configNameMissing,
      noInputElement,
      inputElementMissingColName,
    ]
  )

  const handleSave = () => {
    const tableauObjectId =
      tableau.extensions.dashboardContent?.dashboard.objects.find(
        (o) => o.id === tableau.extensions.dashboardObjectId
      )?.name
    if (tableauObjectId)
      dispatch(saveConfig())
        .unwrap()
        .then((response) => {
          if (response.saved)
            tableau.extensions.ui.closeDialog(
              JSON.stringify({ ...response.config, backdrop: '' })
            )
          else if (response.duplicateName)
            dispatch(
              snackbarsActions.enqeue({
                customMessage: texts.snackbarConfigExists,
                options: { variant: 'error', persist: true },
              })
            )
        })
    else
      dispatch(
        snackbarsActions.enqeue({
          customMessage: texts.snackbarTableauObjectIdMissing,
          options: { variant: 'error', persist: true },
        })
      )
  }

  return disabled ? (
    <Tooltip
      title={
        <>
          {configNameMissing && (
            <Typography variant='body2'>
              {texts.tooltipConfigNameRequired}
            </Typography>
          )}
          {noInputElement && (
            <Typography variant='body2'>
              {texts.tooltipNoInputElement}
            </Typography>
          )}
          {inputElementMissingColName && (
            <Typography variant='body2'>
              {texts.tooltipInputFieldWithoutDbColName}
            </Typography>
          )}
          {!noInputElement && !configNameMissing && configUnchanged && (
            <Typography variant='body2'>
              {texts.tooltipNothingToSave}
            </Typography>
          )}
        </>
      }
    >
      <span>
        <Button
          variant='contained'
          endIcon={<Save />}
          disabled={disabled}
          onClick={handleSave}
        >
          {existingConfig ? texts.buttonTextUpdate : texts.buttonTextSave}
        </Button>
      </span>
    </Tooltip>
  ) : (
    <Button
      variant='contained'
      endIcon={<Save />}
      disabled={disabled}
      onClick={handleSave}
    >
      {existingConfig ? texts.buttonTextUpdate : texts.buttonTextSave}
    </Button>
  )
}

export default ConfigSaveButton
