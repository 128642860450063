// hooks
import { useLanguage } from 'src/hooks/languages'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'

// mui
import {
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  SelectChangeEvent,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { Box } from '@mui/system'

// types
import { FC } from 'react'

// assets
import { textFieldStyleProps } from 'src/assets/helpers/mui'

// redux
import { dimensionsActions } from 'src/redux/dimensions'

interface WorksheetAccordionDetailsProps {
  worksheet: string
  availableWorksheetDimensions: string[]
}

const WorksheetAccordionDetails: FC<WorksheetAccordionDetailsProps> = ({
  worksheet,
  availableWorksheetDimensions,
}) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const configDimensionWorksheets = useAppSelector(
    (state) => state.config.dimensions?.map((d) => d.worksheet) || []
  )
  const configWorksheetDimensions = useAppSelector(
    (state) =>
      state.config.dimensions?.find((wds) => wds.worksheet === worksheet)
        ?.dimensions || []
  )
  const worksheets = useAppSelector(
    (state) => state.dimensions?.map((wds) => wds.worksheet) || []
  )
  const worksheetDimensions = useAppSelector(
    (state) =>
      state.dimensions?.find((wds) => wds.worksheet === worksheet)
        ?.dimensions || []
  )

  return (
    <AccordionDetails>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              disabled={configDimensionWorksheets.indexOf(worksheet) !== -1}
              checked={worksheets.indexOf(worksheet) !== -1}
              onChange={() =>
                dispatch(dimensionsActions.toggleWorksheet(worksheet))
              }
            />
          }
          label={texts.inputLabelEnableCommentingOnWorksheet}
        />
      </FormGroup>

      {worksheets.indexOf(worksheet) > -1 && (
        <TextField
          {...textFieldStyleProps(texts.inputLabelWorksheetDimensions)}
          sx={{ marginTop: 2 }}
          fullWidth
          select
          SelectProps={{
            multiple: true,
            value: worksheetDimensions || [],
            onChange: (event: SelectChangeEvent<unknown>) =>
              dispatch(
                dimensionsActions.toggleWorksheetDim({
                  worksheet,
                  dimensions: event.target.value as string[],
                })
              ),
            renderValue: (selected: unknown) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                }}
              >
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            ),
          }}
        >
          {(availableWorksheetDimensions || []).map((dim) => (
            <MenuItem
              {...{
                key: dim,
                value: dim,
                disabled: configWorksheetDimensions?.indexOf(dim) !== -1,
              }}
            >
              <Checkbox checked={worksheetDimensions?.indexOf(dim) !== -1} />
              <ListItemText primary={dim} />
            </MenuItem>
          ))}
        </TextField>
      )}
    </AccordionDetails>
  )
}

export default WorksheetAccordionDetails
