// redux-toolkit
import { createSlice, isRejected } from '@reduxjs/toolkit'

// redux-thunks
import { saveComment } from './commentThunks'

// types
import { PayloadAction } from '@reduxjs/toolkit'
import { SnackbarKey } from 'notistack'
import { Snackbar, SnackbarContent } from './../types/redux/snackbars'

export const initialSnackbars: Snackbar[] = []

const snackbarsSlice = createSlice({
  name: 'snackbars',
  initialState: initialSnackbars,
  reducers: {
    enqeue: (state, action: PayloadAction<SnackbarContent>) => [
      ...state,
      {
        ...action.payload,
        key: (new Date().getTime() + Math.random()) as SnackbarKey,
        dismissed: false,
      } as Snackbar,
    ],
    dismiss: (state, action: PayloadAction<SnackbarKey>) => {
      let snackbars = state.slice()
      snackbars[
        snackbars.findIndex((snackbar) => snackbar.key === action.payload)
      ].dismissed = true
      return snackbars
    },
    remove: (state, action: PayloadAction<SnackbarKey>) => {
      let snackbars = state.slice()
      snackbars.splice(
        snackbars.findIndex((snackbar) => snackbar.key === action.payload),
        1
      )
      return snackbars
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveComment.fulfilled, (state, action) => {
      if (action.payload.duplicateComment)
        return [
          ...state,
          {
            messageKey: 'dimensionAlreadyCommentedOn',
            options: { variant: 'error', persist: true },
            key: (new Date().getTime() + Math.random()) as SnackbarKey,
            dismissed: false,
          },
        ]
    })
    builder.addMatcher(isRejected, (state, action) => {
      return [
        ...state,
        {
          message: action.error.message,
          options: { variant: 'error', persist: true },
          key: (new Date().getTime() + Math.random()) as SnackbarKey,
          dismissed: false,
        } as Snackbar,
      ]
    })
  },
})

export const snackbarsActions = snackbarsSlice.actions

export default snackbarsSlice.reducer
