// hooks
import { useEffect, useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// components
import MarkSelectionAlert from './MarkSelectionAlert'
import DialogButton from './DialogButton'
import DashboardForm from './DashboardForm'
import ImplementationInstructions from './ImplementationInstructions'

// mui
import { Box } from '@mui/material'

// types
import { FC } from 'react'
import {
  Worksheet,
  DataSource,
  TableauError,
  MarksSelectedEvent,
} from '@tableau/extensions-api-types'
import { MarkSelection } from '../../types/marks'
import { ConfigState } from '../../types/redux/config'
import { DimensionsState } from '../../types/redux/dimensions'

// redux-thunks
import { getSavedFields } from '../../redux/commentThunks'
import {
  checkDatabase,
  checkTableau,
  checkUsername,
} from '../../redux/tableauThunks'

// redux-actions
import { layoutActions } from '../../redux/layout'
import { snackbarsActions } from '../../redux/snackbars'
import { configurationActions } from '../../redux/config'
import { appearanceActions } from 'src/redux/appearance'

// helpers
import { ensure } from '../../assets/helpers/typescript'
import { findColIdx, getLocale } from '../../assets/helpers/tableau'
import { isEqual, uniq, uniqWith } from 'lodash'

const TableauDashboardElement: FC = () => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const config = useAppSelector((state) => state.config)
  const tableauInfo = useAppSelector((state) => state.tableau)
  const fullyInitialized = useAppSelector(
    (state) => state.tableau.fullyInitialized
  )

  const [markSelection, setMarkSelection] = useState<MarkSelection>({
    worksheet: '',
    dimensions: [],
    marks: [],
    commentUids: [],
  })
  const [siblingsUids, setSiblingsUids] = useState<number[]>([])

  // useEffect(() => {
  //   dispatch(checkDatabase())
  // }, [dispatch])

  const loadSetup = useCallback(() => {
    const tableauObjectId: string | undefined =
      tableau.extensions.dashboardContent?.dashboard.objects.find(
        (o) => o.id === tableau.extensions.dashboardObjectId
      )?.name
    if (tableauObjectId) {
      const configJSON = tableau.extensions.settings.get(tableauObjectId)
      if (configJSON) return JSON.parse(configJSON) as ConfigState
    } else
      dispatch(
        snackbarsActions.enqeue({
          customMessage: texts.snackbarTableauObjectIdMissing,
          options: { variant: 'error', persist: true },
        })
      )
  }, [dispatch, texts.snackbarTableauObjectIdMissing])

  // add mark selection event listeners
  const [openDialogOnMarkSelection, setOpenDialogOnMarkSelection] =
    useState(false)

  const addMarkSelectionEventListeners = useCallback(
    (dimensions: DimensionsState) =>
      tableau.extensions.dashboardContent?.dashboard.worksheets.forEach(
        (worksheet: Worksheet) =>
          worksheet.addEventListener(
            tableau.TableauEventType.MarkSelectionChanged,
            (event) => {
              const markSelectionEvent = event as MarksSelectedEvent
              markSelectionEvent.getMarksAsync().then((res) => {
                if (
                  dimensions &&
                  dimensions.length > 0 &&
                  res.data.map((dt) => dt.totalRowCount > 0).some((e) => e)
                ) {
                  const worksheetDimensions = dimensions.find(
                    (wsDims) =>
                      wsDims.worksheet === markSelectionEvent.worksheet.name
                  )?.dimensions
                  if (worksheetDimensions) {
                    let openDialogOnMarkSelectionDefault = false

                    let comUids: number[] = []
                    let sibUids: number[] = []
                    let marks: string[][] = []

                    res.data.forEach((dataTable) => {
                      const openDialogOnMarkSelectionIdx = findColIdx({
                        dataTable,
                        key: 'OpenDialogOnMarkSelection',
                      })

                      const comUidColIdx = findColIdx({
                        dataTable,
                        key: 'ATTR(Comment Uid)',
                      })

                      const siblingsUidColIdx = findColIdx({
                        dataTable,
                        key: 'ATTR(Siblings Uid)',
                      })

                      const dimensionsColIdc = worksheetDimensions
                        .map((dimension) =>
                          findColIdx({ dataTable, key: dimension })
                        )
                        .filter((idx) => idx !== -1)

                      if (
                        dimensionsColIdc.length === worksheetDimensions.length
                      ) {
                        for (
                          let rowIdx = 0;
                          rowIdx < dataTable.totalRowCount;
                          rowIdx++
                        ) {
                          if (
                            openDialogOnMarkSelectionIdx > -1 &&
                            rowIdx === 0 &&
                            dataTable.data[rowIdx][openDialogOnMarkSelectionIdx]
                              .nativeValue
                          )
                            openDialogOnMarkSelectionDefault = true
                          if (comUidColIdx > -1)
                            comUids.push(
                              dataTable.data[rowIdx][comUidColIdx].nativeValue
                            )
                          if (siblingsUidColIdx > -1)
                            sibUids.push(
                              dataTable.data[rowIdx][siblingsUidColIdx]
                                .nativeValue
                            )
                          if (dimensionsColIdc.every((i) => i > -1))
                            marks.push(
                              dimensionsColIdc.map(
                                (colIdx) =>
                                  dataTable.data[rowIdx][colIdx].nativeValue
                              )
                            )
                        }
                      }
                    })
                    comUids = comUids.filter((uid) => typeof uid === 'number')
                    sibUids = sibUids.filter((uid) => typeof uid === 'number')
                    setSiblingsUids(uniq(sibUids))
                    setMarkSelection(
                      JSON.parse(
                        JSON.stringify({
                          worksheet: markSelectionEvent.worksheet.name,
                          dimensions: worksheetDimensions,
                          marks: uniqWith(marks, isEqual),
                          commentUids: uniq(comUids.filter((e) => e !== null)),
                        })
                      )
                    )
                    setOpenDialogOnMarkSelection(
                      openDialogOnMarkSelectionDefault
                    )
                    if (sibUids.length < 2 && comUids.length > 0)
                      dispatch(
                        getSavedFields({
                          commentUid: comUids[0],
                          worksheet: markSelectionEvent.worksheet.name,
                        })
                      )
                    else dispatch(layoutActions.reset())
                  }
                } else {
                  dispatch(layoutActions.reset())
                  setSiblingsUids([])
                  setMarkSelection({
                    worksheet: '',
                    dimensions: [],
                    marks: [],
                    commentUids: [],
                  })
                }
              })
            }
          )
      ),
    [dispatch]
  )

  useEffect(() => {
    // extension configuration
    const configure = () => {
      const popupUrl = `${window.location.origin}${process.env.PUBLIC_URL}/settings`
      const savedSetup = loadSetup()
      tableau.extensions.ui
        .displayDialogAsync(
          popupUrl,
          savedSetup ? JSON.stringify(savedSetup) : '',
          {
            height: 720,
            width: 1240,
          }
        )
        .then((setupJSON: string) => {
          if (setupJSON) {
            const savedSetup = JSON.parse(setupJSON)
            if (savedSetup.dimensions)
              addMarkSelectionEventListeners(savedSetup.dimensions)
            const tableauObjectId: string | undefined =
              tableau.extensions.dashboardContent?.dashboard.objects.find(
                (o) => o.id === tableau.extensions.dashboardObjectId
              )?.name
            if (tableauObjectId) {
              dispatch(configurationActions.set(savedSetup))
              tableau.extensions.settings.set(tableauObjectId, setupJSON)
              tableau.extensions.settings.saveAsync()
            }
          }
        })
        .catch((err: TableauError) => console.log(err.message))
        .finally(() => {
          tableau.extensions.dashboardContent?.dashboard.worksheets.forEach(
            (worksheet) => worksheet.clearSelectedMarksAsync()
          )
          dispatch(checkUsername())
          dispatch(checkTableau())
        })
      return {}
    }

    // extensions initialization
    tableau.extensions.initializeAsync({ configure }).then(() => {
      // tableau data CACHE HERE

      // get saved settings if exist
      const savedSetup = loadSetup()
      if (savedSetup) {
        dispatch(configurationActions.set(savedSetup))
        if (savedSetup.dimensions) {
          // add mark selection event listeners
          addMarkSelectionEventListeners(savedSetup.dimensions)
        }
        // set language correctly
        else dispatch(appearanceActions.update({ locale: getLocale() }))
      }

      // check username
      dispatch(checkDatabase())
      dispatch(checkUsername())
      dispatch(checkTableau())
    })
  }, [dispatch, loadSetup, addMarkSelectionEventListeners])

  const refreshCommentDataSources = useCallback(async () => {
    const dataSourceFetchPromises: Array<Promise<DataSource[] | undefined>> = []
    tableau.extensions.dashboardContent?.dashboard.worksheets.forEach(
      (worksheet: Worksheet) =>
        dataSourceFetchPromises.push(
          worksheet
            .getDataSourcesAsync()
            .then((r) => r)
            .catch((e) => undefined)
        )
    )
    let fetchResults = (await Promise.all(dataSourceFetchPromises))
      .filter((r: DataSource[] | void): r is DataSource[] => !!r)
      .flat()
    let dataSourcesCheck: { [index: string]: boolean } = {}
    fetchResults.forEach((dataSource) => {
      if (!dataSourcesCheck[dataSource.id] && !dataSource.isExtract)
        dataSource.getLogicalTablesAsync().then((logicalTables) => {
          const setupTableName = `cb_comments_${config.name}`
          if (
            logicalTables
              .map(
                (logicalTable) =>
                  //logicalTable.id.slice(0, setupTableName.length) === setupTableName
                  
                  dataSource.fields.find(
                    (field) => field.id === 'Comment Uid'
                  )
              )
              .some((e) => e)
          ) {
            dataSource.refreshAsync()
            dataSourcesCheck[dataSource.id] = true
          }
        })
    })
    tableau.extensions.dashboardContent?.dashboard.worksheets
      .find((worksheet) => worksheet.name === markSelection.worksheet)
      ?.clearSelectedMarksAsync()
      .finally(() => dispatch(layoutActions.set(config.layout || [])))
  }, [config, markSelection, dispatch])

  const openDialog = useCallback(() => {
    const popupUrl = `${window.location.origin}${process.env.PUBLIC_URL}/dialog`
    const dialogWidth: number = (1240 - 170) / 2
    const dialogHeight: number = 720

    console.log("config", config)

    tableau.extensions.ui
      .displayDialogAsync(
        popupUrl,
        JSON.stringify({
          markSelection,
          config,
          tableauInfo,
        }),
        {
          height: dialogHeight,
          width: dialogWidth,
        }
      )
      .then((refresh) => {
        if (refresh === 'refresh') {
          ensure(
            tableau.extensions.dashboardContent?.dashboard.worksheets.find(
              (ws) => ws.name === markSelection.worksheet
            )
          )
            .clearSelectedMarksAsync()
            .then((_) => refreshCommentDataSources())
        }
      })
      .catch((err: TableauError) => console.log(err.message))
  }, [markSelection, config, refreshCommentDataSources, tableauInfo])

  useEffect(() => {
    if (openDialogOnMarkSelection) openDialog()
  }, [openDialogOnMarkSelection, openDialog])

  return (
    <Box
      id='opencommentdialog_container'
      sx={{ height: '100vh', width: '100vw' }}
    >
      {fullyInitialized && config.appearance ? (
        siblingsUids.length > 1 ||
        (markSelection.commentUids.length > 0 &&
          markSelection.marks.length > markSelection.commentUids.length) ||
        (markSelection.commentUids.length > 1 && siblingsUids.length !== 1) ? (
          <MarkSelectionAlert {...{ siblingsUids, markSelection }} />
        ) : config.appearance.popupDialog ? (
          <DialogButton {...{ markSelection, openDialog }} />
        ) : (
          <DashboardForm {...{ markSelection, refreshCommentDataSources }} />
        )
      ) : (
        <ImplementationInstructions />
      )}
    </Box>
  )
}

export default TableauDashboardElement
