import { useState, useEffect, useCallback } from 'react'

type FilterConditionally<Source, KeyCondition> = Pick<
  Source,
  {
    [K in keyof Source]: K extends KeyCondition ? K : never
  }[keyof Source]
>

const a = ['altKey', 'shiftKey', 'ctrlKey', 'metaKey'] as const
type A = typeof a[number]

type OptKey = keyof FilterConditionally<KeyboardEvent, A>

const allOptKeys = ['altKey', 'shiftKey', 'ctrlKey', 'metaKey'] as OptKey[]

export const useKeyPress = (targetKey: string, optKeys?: OptKey[]) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  const testOptKeys = useCallback(
    (event: KeyboardEvent) => {
      let pressed = true
      // Only execute if optKeys property exists
      if (optKeys) {
        // Check optKeys pressed
        optKeys.forEach((optKey) => {
          if (typeof event[optKey] === 'boolean') pressed = !!event[optKey]
        })
        // Check if any other optKey is pressed (if so, the shortcut is not valid)
        allOptKeys.forEach((optKey) => {
          if (optKeys.indexOf(optKey) === -1) pressed = !event[optKey]
        })
      }
      return pressed
    },
    [optKeys]
  )

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (event: KeyboardEvent) =>
      event.key === targetKey && testOptKeys(event) && setKeyPressed(true),
    [targetKey, testOptKeys]
  )

  // If released key is our target key then set to false
  const upHandler = useCallback(
    (event: KeyboardEvent) =>
      event.key === targetKey && testOptKeys(event) && setKeyPressed(false),
    [targetKey, testOptKeys]
  )

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [upHandler, downHandler]) // Empty array ensures that effect is only run on mount and unmount
  return keyPressed
}
