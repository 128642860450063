// mui
import { SvgIcon } from '@mui/material'

const CombleauLogo = () => {
  return (
    <SvgIcon
      fontSize='large'
      // viewBox='0 0 568.2 64.4'
      viewBox='0 0 212 24'
      sx={{
        display: {
          width: '212px',
          xs: 'none',
          md: 'flex',
          alignSelf: 'center',
        },
        mr: 1,
      }}
    >
      <svg
        version='1.1'
        id='Ebene_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 493.9 64.4'
        enableBackground='0 0 493.9 64.4'
        xmlSpace='preserve'
      >
        <g id='Original' display='none'>
          <polyline
            display='inline'
            fill='none'
            points='32.6,-250.9 645.8,-250.9 645.8,443.2 32.6,443.2 	'
          />
          <g display='inline'>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M30.1-130.4c-5.9,0-11.1-1.2-15.8-3.7S6-140,3.3-144.5c-2.7-4.4-4-9.4-4-15s1.3-10.6,4-15s6.3-7.9,11-10.4
					s9.9-3.7,15.8-3.7c5.1,0,9.7,0.9,13.8,2.7s7.5,4.4,10.3,7.8L44.1-169c-3.6-4.4-8-6.6-13.2-6.6c-3,0-5.8,0.7-8.1,2
					c-2.4,1.3-4.2,3.2-5.5,5.6c-1.3,2.4-2,5.2-2,8.4s0.7,5.9,2,8.4c1.3,2.4,3.1,4.3,5.5,5.6s5.1,2,8.1,2c5.2,0,9.6-2.2,13.2-6.6
					l10.1,9.1c-2.7,3.4-6.1,6-10.3,7.9C39.8-131.3,35.2-130.4,30.1-130.4z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M84.4-131.5l-0.2-29.7L69.9-137h-7l-14.3-23.4v28.9H33.9v-56h13l19.7,32.3l19.2-32.3h13l0.2,56H84.4z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M96-160.7c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H52.9v-56
					h28.6c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C100.2-163.6,98.4-161.9,96-160.7z M68.6-176.1v10.8
					h10.9c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L68.6-176.1L68.6-176.1z M81.9-143c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H68.6
					v11.4H81.9z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M68.3-187.5h15.8v43.4h26.7v12.6H68.3V-187.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M125.5-143.8v12.3h-45v-56h43.9v12.3H96.2v9.4h24.9v11.8H96.2v10.2H125.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M131.5-142.4h-23.7l-4.4,10.9H87.2l24.7-56h15.6l24.8,56h-16.5L131.5-142.4z M126.8-154.1l-7.2-17.9
					l-7.2,17.9H126.8z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M135.5-130.4c-8.3,0-14.8-2.3-19.4-6.8s-6.9-11-6.9-19.3v-31H125v30.6c0,9,3.5,13.4,10.6,13.4
					c7,0,10.6-4.5,10.6-13.4v-30.6h15.6v31c0,8.3-2.3,14.8-6.9,19.3C150.3-132.7,143.8-130.4,135.5-130.4z'
                />
              </g>
            </g>
          </g>
          <polyline
            display='inline'
            fill='none'
            points='33.5,-250.6 646.7,-250.6 646.7,443.5 33.5,443.5 	'
          />
          <path
            display='inline'
            fill='#0096DC'
            d='M121.8,17.6c-2.7-4.4-6.4-7.9-11.1-10.4s-10-3.8-16-3.8c-5.9,0-11.2,1.3-16,3.8c-4.7,2.5-8.4,6-11.1,10.4
		s-4,9.4-4,15s1.3,10.5,4,15C70.3,52,74,55.5,78.7,58s10,3.8,16,3.8c5.9,0,11.3-1.3,16-3.8s8.4-6,11.1-10.4s4-9.4,4-15
		S124.5,22.1,121.8,17.6z'
          />
          <path
            display='inline'
            fill='#FFFFFF'
            d='M98.4,47.9H91c-6.6,0-12-5.4-12-12v-7c0-6.6,5.4-12,12-12h7.4c6.6,0,12,5.4,12,12v7
		C110.4,42.5,105,47.9,98.4,47.9z'
          />
          <path
            display='inline'
            fill='#FFFFFF'
            d='M82.5,49.8c-0.4-0.5,1.1-1.5,1.1-3.2c0-1.4-1-2-2.1-3.6c-1.9-2.9-2.2-6-1.9-6.1c0.5-0.2,3.3,6.5,6,6.2h0.1
		c0,0,0.1,0,0.2-0.1c1.9-0.7,5.4,0.3,5.4,0.3h0.1c0.1,0,0.1,0,0.2,0c0.2,0.1,12,1.8,12,2.8c0,0.6-5,1.9-9.6,1.9
		c-0.9,0-4.5,0-6.9,0.9c-0.5,0.2-0.8,0.3-1.4,0.5C83.9,49.8,82.8,50.2,82.5,49.8z'
          />
        </g>
        <g id='Ebene_6' display='none'>
          <g display='inline'>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M29-130c-5.9,0-11.1-1.2-15.8-3.7s-8.3-5.9-11-10.4c-2.7-4.4-4-9.4-4-15s1.3-10.6,4-15s6.3-7.9,11-10.4
					s9.9-3.7,15.8-3.7c5.1,0,9.7,0.9,13.8,2.7s7.5,4.4,10.3,7.8L43-168.5c-3.6-4.4-8-6.6-13.2-6.6c-3,0-5.8,0.7-8.1,2
					c-2.4,1.3-4.2,3.2-5.5,5.6c-1.3,2.4-2,5.2-2,8.4s0.7,5.9,2,8.4c1.3,2.4,3.1,4.3,5.5,5.6s5.1,2,8.1,2c5.2,0,9.6-2.2,13.2-6.6
					l10.1,9.1c-2.7,3.4-6.1,6-10.3,7.9C38.8-130.9,34.2-130,29-130z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M83.4-131.1l-0.2-29.7l-14.4,24.2h-7L47.5-160v28.9H32.8v-56h13l19.7,32.3l19.2-32.3h13l0.2,56H83.4z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M94.9-160.3c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H51.9v-56
					h28.6c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C99.2-163.2,97.3-161.5,94.9-160.3z M67.6-175.7v10.8
					h10.9c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L67.6-175.7L67.6-175.7z M80.8-142.5c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H67.6
					v11.4H80.8z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M67.3-187.1h15.8v43.4h26.7v12.6H67.3V-187.1z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M124.4-143.4v12.3h-45v-56h43.9v12.3H95.1v9.4H120v11.8H95.1v10.2H124.4z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M130.4-142h-23.7l-4.4,10.9H86.2l24.7-56h15.6l24.8,56h-16.5L130.4-142z M125.8-153.7l-7.2-17.9l-7.2,17.9
					H125.8z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M134.5-130c-8.3,0-14.8-2.3-19.4-6.8s-6.9-11-6.9-19.3v-31H124v30.6c0,9,3.5,13.4,10.6,13.4
					c7,0,10.6-4.5,10.6-13.4v-30.6h15.6v31c0,8.3-2.3,14.8-6.9,19.3C149.2-132.2,142.8-130,134.5-130z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <path
              fill='#0096DC'
              d='M120.8,18c-2.7-4.4-6.4-7.9-11.1-10.4s-10-3.8-16-3.8c-5.9,0-11.2,1.3-16,3.8c-4.7,2.5-8.4,6-11.1,10.4
			c-2.7,4.4-4,9.4-4,15s1.3,10.5,4,15c2.7,4.4,6.4,7.9,11.1,10.4s10,3.8,16,3.8c5.9,0,11.3-1.3,16-3.8s8.4-6,11.1-10.4s4-9.4,4-15
			S123.5,22.5,120.8,18z M109.8,37.8c0,6.6-5.4,12-12,12H89c-6.6,0-12-5.4-12-12v-8.4c0-6.6,5.4-12,12-12h8.8c6.6,0,12,5.4,12,12
			V37.8z'
            />
          </g>
          <path
            display='inline'
            fill='#0096DC'
            d='M65.6,61.8c-0.5-0.9,1-1.7,1.7-4.4c1.1-3.9-0.5-8.1-2-11.8c-1.1-2.8-1.8-3.6-1.3-4.7c0.9-2,4.7-3.2,6.8-1.9
		c3.7,2.4,0.4,13,3.1,13.9c1,0.3,5.3,0.3,5.4,0.3h0.1c0.1,0,0.1,0,0.2,0c0.2,0.1,10.1-0.1,12,2.8c0.8,1.2,0.5,3.3-0.5,4.5
		c-2.1,2.4-6.2-0.3-14.2,0.1C70.5,61.1,66.4,63.3,65.6,61.8z'
          />
          <path display='inline' fill='#0096DC' d='M-150.9,110.1' />
        </g>
        <g id='Kopie_Bausteine' display='none'>
          <g display='inline'>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M29.5-130.6c-5.9,0-11.1-1.2-15.8-3.7s-8.3-5.9-11-10.4c-2.7-4.4-4-9.4-4-15s1.3-10.6,4-15s6.3-7.9,11-10.4
					s9.9-3.7,15.8-3.7c5.1,0,9.7,0.9,13.8,2.7s7.5,4.4,10.3,7.8l-10.1,9.1c-3.6-4.4-8-6.6-13.2-6.6c-3,0-5.8,0.7-8.1,2
					c-2.4,1.3-4.2,3.2-5.5,5.6c-1.3,2.4-2,5.2-2,8.4s0.7,5.9,2,8.4c1.3,2.4,3.1,4.3,5.5,5.6s5.1,2,8.1,2c5.2,0,9.6-2.2,13.2-6.6
					l10.1,9.1c-2.7,3.4-6.1,6-10.3,7.9C39.2-131.5,34.6-130.6,29.5-130.6z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M83.8-131.7l-0.2-29.7l-14.4,24.2h-7l-14.3-23.4v28.9H33.2v-56h13L66-155.4l19.2-32.3h13l0.2,56H83.8z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M95.4-160.9c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H52.3v-56
					H81c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C99.6-163.8,97.8-162.1,95.4-160.9z M68-176.2v10.8
					h10.9c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L68-176.2L68-176.2z M81.3-143.1c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H68v11.4
					H81.3z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M67.7-187.7h15.8v43.4h26.7v12.6H67.7V-187.7z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M124.9-143.9v12.3h-45v-56h43.9v12.3H95.6v9.4h24.9v11.8H95.6v10.2H124.9z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M130.9-142.6h-23.7l-4.4,10.9H86.6l24.7-56H127l24.8,56h-16.5L130.9-142.6z M126.2-154.2l-7.2-17.9
					l-7.2,17.9H126.2z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M134.9-130.6c-8.3,0-14.8-2.3-19.4-6.8s-6.9-11-6.9-19.3v-31h15.8v30.6c0,9,3.5,13.4,10.6,13.4
					c7,0,10.6-4.5,10.6-13.4v-30.6h15.6v31c0,8.3-2.3,14.8-6.9,19.3C149.7-132.8,143.2-130.6,134.9-130.6z'
                />
              </g>
            </g>
          </g>
          <polyline
            display='inline'
            fill='none'
            points='32.9,-250.8 646.1,-250.8 646.1,443.3 32.9,443.3 	'
          />
          <g display='inline'>
            <path
              fill='#0096DC'
              d='M121.2,17.5c-2.7-4.4-6.4-7.9-11.1-10.4s-10-3.8-16-3.8c-5.9,0-11.2,1.3-16,3.8c-4.7,2.5-8.4,6-11.1,10.4
			s-4,9.4-4,15s1.3,10.5,4,15c2.7,4.4,6.4,7.9,11.1,10.4s10,3.8,16,3.8c5.9,0,11.3-1.3,16-3.8s8.4-6,11.1-10.4s4-9.4,4-15
			S123.9,21.9,121.2,17.5z M109.8,35.8c0,6.6-5.4,12-12,12h-4.4c-0.9,0-2.2,0.1-3.7,0.5c-1.8,0.5-2.4,1.1-4.7,1.9
			c-2,0.8-4.2,1.6-4.8,1c-0.5-0.6,0.8-1.7,1-3.9c0.1-1.3-0.1-2.4-0.4-3.1c-0.8-1.4-1.6-3.3-2.1-5.7c-0.2-1-0.3-1.9-0.3-2.7v-7
			c0-6.6,5.4-12,12-12h7.4c6.6,0,12,5.4,12,12V35.8z'
            />
          </g>
        </g>
        <g id='langer_schlenker' display='none'>
          <g display='inline'>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M29.7-133.5c-5.9,0-11.1-1.2-15.8-3.6c-4.7-2.4-8.3-5.7-11-10s-4-9.1-4-14.5s1.3-10.2,4-14.5s6.3-7.6,11-10
					s9.9-3.6,15.8-3.6c5.1,0,9.7,0.9,13.8,2.6c4.1,1.7,7.5,4.2,10.3,7.5l-10.1,8.8c-3.6-4.2-8-6.3-13.2-6.3c-3,0-5.8,0.6-8.1,1.9
					c-2.4,1.3-4.2,3.1-5.5,5.4c-1.3,2.3-2,5-2,8s0.7,5.7,2,8.1c1.3,2.3,3.1,4.1,5.5,5.4c2.4,1.3,5.1,1.9,8.1,1.9
					c5.2,0,9.6-2.1,13.2-6.3l10.1,8.8c-2.7,3.3-6.1,5.8-10.3,7.6C39.4-134.3,34.8-133.5,29.7-133.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M84-133.5l-0.2-29.7L69.5-139h-7l-14.3-23.4v28.9H33.5v-56h13l19.7,32.3l19.2-32.3h13l0.2,56H84z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M95.6-162.7c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H52.5v-56
					h28.6c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C99.8-165.6,98-163.9,95.6-162.7z M68.2-178v10.8
					h10.9c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L68.2-178L68.2-178z M81.5-144.9c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H68.2v11.4
					H81.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M67.9-189.5h15.8v43.4h26.7v12.6H67.9V-189.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M125.1-145.7v12.3h-45v-56H124v12.3H95.8v9.4h24.9v11.8H95.8v10.2H125.1z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M131.1-144.3h-23.7l-4.4,10.9H86.8l24.7-56h15.6l24.8,56h-16.5L131.1-144.3z M126.4-156l-7.2-17.9L112-156
					H126.4z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M135.1-133.5c-8.3,0-14.8-2.2-19.4-6.7s-6.9-10.8-6.9-18.9v-30.4h15.8v30c0,8.8,3.5,13.2,10.6,13.2
					c7,0,10.6-4.4,10.6-13.2v-30h15.6v30.4c0,8.2-2.3,14.5-6.9,18.9C149.9-135.7,143.4-133.5,135.1-133.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <path
              fill='#0096DC'
              d='M121.4,16.3c-2.7-4.3-6.4-7.6-11.1-10s-10-3.6-16-3.6c-5.9,0-11.2,1.2-16,3.6c-4.7,2.4-8.4,5.7-11.1,10
			s-4,9.1-4,14.4s1.3,10.1,4,14.4s6.4,7.6,11.1,10s10,3.6,16,3.6c5.9,0,11.3-1.2,16-3.6c4.7-2.4,8.4-5.8,11.1-10
			c2.7-4.3,4-9.1,4-14.4S124.1,20.5,121.4,16.3z M110.5,35.2c0,6.3-5.4,11.5-12,11.5h-8.8c-6.6,0-12-5.2-12-11.5v-8.1
			c0-6.3,5.4-11.5,12-11.5h8.8c6.6,0,12,5.2,12,11.5V35.2z'
            />
          </g>
          <path
            display='inline'
            fill='#0096DC'
            d='M60.7,62.3c-0.4-1.2,2.7-2.3,5.4-7.3c1.1-2,2.4-4.4,2.4-7.6c0-3-1.1-4-0.5-6.4c0.6-2.3,2.5-4.6,3.5-4.3
		c2.2,0.6,0.1,12.9,3.1,13.9c1,0.3,5.3,0.3,5.4,0.3h0.1c0.1,0,0.1,0,0.2,0c0.4,0.1,6.7,2.5,12,2.8c3.3,0.2,4.8-0.6,4.9-0.2
		c0.3,0.9-10,5.4-21.6,7.9C74,61.8,61.6,64.6,60.7,62.3z'
          />
          <path display='inline' fill='#0096DC' d='M-150.3,107.7' />
        </g>
        <g id='var_4' display='none'>
          <g display='inline'>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M28.6-134.5c-5.9,0-11.1-1.2-15.8-3.6c-4.7-2.4-8.3-5.7-11-10s-4-9.1-4-14.5s1.3-10.2,4-14.5s6.3-7.6,11-10
					s9.9-3.6,15.8-3.6c5.1,0,9.7,0.9,13.8,2.6c4.1,1.7,7.5,4.2,10.3,7.5l-10.1,8.8c-3.6-4.2-8-6.3-13.2-6.3c-3,0-5.8,0.6-8.1,1.9
					c-2.4,1.3-4.2,3.1-5.5,5.4c-1.3,2.3-2,5-2,8s0.7,5.7,2,8.1c1.3,2.3,3.1,4.1,5.5,5.4c2.4,1.3,5.1,1.9,8.1,1.9
					c5.2,0,9.6-2.1,13.2-6.3l10.1,8.8c-2.7,3.3-6.1,5.8-10.3,7.6C38.3-135.4,33.7-134.5,28.6-134.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M82.9-134.5l-0.2-29.7L68.3-140h-7L47-163.4v28.9H32.3v-56h13L65-158.2l19.2-32.3h13l0.2,56H82.9z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M94.5-163.7c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H51.4v-56
					H80c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C98.7-166.6,96.9-165,94.5-163.7z M67.1-179.1v10.8H78
					c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L67.1-179.1L67.1-179.1z M80.4-146c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H67.1v11.4
					H80.4z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M66.8-190.5h15.8v43.4h26.7v12.6H66.8V-190.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M124-146.8v12.3H79v-56h43.9v12.3H94.7v9.4h24.9v11.8H94.7v10.2H124z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M130-145.4h-23.7l-4.4,10.9H85.7l24.7-56H126l24.8,56h-16.5L130-145.4z M125.3-157.1l-7.2-17.9l-7.2,17.9
					H125.3z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M134-134.5c-8.3,0-14.8-2.2-19.4-6.7s-6.9-10.8-6.9-18.9v-30.4h15.8v30c0,8.8,3.5,13.2,10.6,13.2
					c7,0,10.6-4.4,10.6-13.2v-30h15.6v30.4c0,8.2-2.3,14.5-6.9,18.9C148.8-136.7,142.3-134.5,134-134.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <path
              fill='#0096DC'
              d='M120.3,15.2c-2.7-4.3-6.4-7.6-11.1-10s-10-3.6-16-3.6c-5.9,0-11.2,1.2-16,3.6c-4.7,2.4-8.4,5.7-11.1,10
			c-2.7,4.3-4,9.1-4,14.4s1.3,10.1,4,14.4c2.7,4.3,6.4,7.6,11.1,10s10,3.6,16,3.6c5.9,0,11.3-1.2,16-3.6c4.7-2.4,8.4-5.8,11.1-10
			c2.7-4.3,4-9.1,4-14.4S123,19.5,120.3,15.2z M109.3,34.2c0,6.3-5.4,11.5-12,11.5h-8.8c-6.6,0-12-5.2-12-11.5v-8.1
			c0-6.3,5.4-11.5,12-11.5h8.8c6.6,0,12,5.2,12,11.5V34.2z'
            />
          </g>
          <path
            display='inline'
            fill='#0096DC'
            d='M51.4,60.2c-0.1-0.8,3.2-0.9,7.7-4c1.8-1.3,8.5-5.9,8.2-11.4c-0.1-1.9-0.9-2.8-0.4-4.8
		c0.5-2.3,2.5-4.6,3.5-4.3c2.2,0.6,0.1,12.9,3.1,13.9c1,0.3,5.3,0.3,5.4,0.3H79c0.1,0,0.1,0,0.2,0c0.4,0.1,6.7,2.5,12,2.8
		c1.3,0.1,1-0.1,2.5,0c4.7,0.1,10.4,1.4,10.4,2.6c0.1,2.2-19.8,4.3-29.7,5.1C62.9,61.4,51.5,61.5,51.4,60.2z'
          />
          <path display='inline' fill='#0096DC' d='M-151.4,106.7' />
          <path display='inline' fill='#0096DC' d='M218.2,96.8' />
          <path
            display='inline'
            fill='#FFFFFF'
            d='M76.5,50.2c-0.3-0.5,0.2-0.8,1.1-3.7c0.9-3,1.6-4.6,1.1-6.3c-0.1-0.5-0.5-1.4-0.2-2.4c0.3-1.2,1.2-2.3,1.8-2.1
		c1.1,0.3,0.1,6.5,1.6,7c0.5,0.2,2.6,0.1,2.7,0.2c0,0,0,0,0.1,0h0.1c0.2,0.1,3.4,1.3,6.1,1.4c0.4,0,0.5,0,0.8,0l0,0
		c0.2,0,0.3,0,0.5,0c0.3,0,7.3-1.7,7.4-1.3c0.1,0.3-4.5,3.3-11.5,5.7C86.1,49.3,77.7,52.2,76.5,50.2z'
          />
          <path display='inline' fill='#FFFFFF' d='M124.8,133.2' />
        </g>
        <g id='var_5'>
          <g>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M28.1-134.6c-5.9,0-11.1-1.2-15.8-3.6c-4.7-2.4-8.3-5.7-11-10s-4-9.1-4-14.5s1.3-10.2,4-14.5s6.3-7.6,11-10
					s9.9-3.6,15.8-3.6c5.1,0,9.7,0.9,13.8,2.6c4.1,1.7,7.5,4.2,10.3,7.5l-10.1,8.8c-3.6-4.2-8-6.3-13.2-6.3c-3,0-5.8,0.6-8.1,1.9
					c-2.4,1.3-4.2,3.1-5.5,5.4c-1.3,2.3-2,5-2,8s0.7,5.7,2,8.1c1.3,2.3,3.1,4.1,5.5,5.4c2.4,1.3,5.1,1.9,8.1,1.9
					c5.2,0,9.6-2.1,13.2-6.3l10.1,8.8c-2.7,3.3-6.1,5.8-10.3,7.6C37.8-135.5,33.2-134.6,28.1-134.6z'
                />
              </g>
            </g>
          </g>
          <g>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M82.4-134.6l-0.2-29.7l-14.4,24.2h-7l-14.3-23.4v28.9H31.9v-56h13l19.7,32.3l19.2-32.3h13l0.2,56H82.4z'
                />
              </g>
            </g>
          </g>
          <g>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M94-163.8c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H51v-56
					h28.6c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C98.3-166.7,96.4-165.1,94-163.8z M66.6-179.2v10.8
					h10.9c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L66.6-179.2L66.6-179.2z M79.9-146.1c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H66.6
					v11.4H79.9z'
                />
              </g>
            </g>
          </g>
          <g>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M66.3-190.6h15.8v43.4h26.7v12.6H66.3V-190.6z'
                />
              </g>
            </g>
          </g>
          <g>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M123.5-146.9v12.3h-45v-56h43.9v12.3H94.2v9.4h24.9v11.8H94.2v10.2H123.5z'
                />
              </g>
            </g>
          </g>
          <g>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M129.5-145.5h-23.7l-4.4,10.9H85.2l24.7-56h15.6l24.8,56h-16.5L129.5-145.5z M124.9-157.2l-7.2-17.9
					l-7.2,17.9H124.9z'
                />
              </g>
            </g>
          </g>
          <g>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M133.5-134.6c-8.3,0-14.8-2.2-19.4-6.7s-6.9-10.8-6.9-18.9v-30.4H123v30c0,8.8,3.5,13.2,10.6,13.2
					c7,0,10.6-4.4,10.6-13.2v-30h15.6v30.4c0,8.2-2.3,14.5-6.9,18.9C148.3-136.9,141.9-134.6,133.5-134.6z'
                />
              </g>
            </g>
          </g>
          <path fill='#0096DC' d='M-151.9,106.6' />
          <path fill='#0096DC' d='M217.8,96.7' />
          <path fill='#FFFFFF' d='M124.4,133.1' />
          <path
            fill='#0096DC'
            d='M67.5,58.6c-0.9-1.5,1-2.6,0.8-7s-2.5-7.9-2.1-8.1c0.4-0.2,1.9,2.9,3.3,2.6c1.1-0.2,1.4-2.3,1.8-2.1
		c1.1,0.3,0.1,6.5,1.6,7c0.5,0.2,2.6,0.1,2.7,0.2c0,0,0,0,0.1,0h0.1c0.2,0.1,3.4,1.3,6.1,1.4c0.4,0,0.5,0,0.8,0l0,0
		c0.1,0,0.1,0,0.2,0s0.1,0,0.4,0c0.5,0,19.4-0.4,19.7,1.5c0.1,0.9-3.8,2-4.6,2.3c-4.1,1.2-7.5,1.1-8.2,1
		C78.5,56.9,69.3,61.8,67.5,58.6z'
          />
          <g>
            <path
              fill='none'
              d='M96.9,45.6c1,0,1.9-0.1,2.8-0.3c-1,0.2-2.5,0.3-3.5,0.3H96.9z'
            />
            <path
              fill='none'
              d='M100.5,45.1c-0.1,0-0.1,0-0.2,0.1C100.4,45.1,100.4,45.1,100.5,45.1z'
            />
            <path
              fill='none'
              d='M96.9,14.4h-8.8c-6.6,0-12,5.2-12,11.5V34c0,1.2,0.2,2.5,0.6,3.6c-0.1-0.4-0.2-0.7,0-0.8
			c0.4-0.3,1.5,1.3,2.4,1s0.9-1.9,1.3-1.8c0.9,0.1,0.6,4.7,1.8,4.8c0.4,0,2.1-0.3,2.2-0.3c0,0,0,0,0.1,0h0.1c0.2,0,2.8,0.4,5,0.1
			c0.4-0.1,0.4-0.1,0.6-0.1l0,0h0.1c0.1,0,0.1,0,0.3,0c0.3,0,14.5-4.3,15.9-2c0.8,1.3-2.1,5.3-5.6,6.6c4.7-1.6,8.1-5.9,8.1-10.9V26
			C108.9,19.6,103.5,14.4,96.9,14.4z'
            />
            <path
              fill='#0096DC'
              d='M119.8,15.1c-2.7-4.3-6.4-7.6-11.1-10s-10-3.6-16-3.6c-5.9,0-11.2,1.2-16,3.6c-4.7,2.4-8.4,5.7-11.1,10
			c-2.7,4.3-4,9.1-4,14.4s1.3,10.1,4,14.4c2.7,4.3,6.4,7.6,11.1,10s10,3.6,16,3.6c5.9,0,11.3-1.2,16-3.6c4.7-2.4,8.4-5.8,11.1-10
			c2.7-4.3,4-9.1,4-14.4S122.5,19.4,119.8,15.1z M108.9,34.1c0,5-3.4,9.3-8.1,10.9c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1
			c-0.1,0-0.3,0.1-0.4,0.1s-0.1,0-0.2,0c-0.9,0.2-1.9,0.3-2.8,0.3h-0.6c-2.1,0.1-2.8,0-4.4,0.1c-0.4,0-0.8,0.1-1.1,0.1
			c-1.5,0.2-2.6,0.6-3.2,0.7c-5,1.6-8.6,3.6-9.8,2.5c-1-1,0.9-2.9,0.3-7c-0.3-1.9-1.1-3.6-1.4-4.5c-0.4-1.1-0.6-2.3-0.6-3.6V26
			c0-6.3,5.4-11.5,12-11.5h8.8c6.6,0,12,5.2,12,11.5C108.9,26,108.9,34.1,108.9,34.1z'
            />
            <path
              fill='#FFFFFF'
              d='M100.8,44.9c-0.1,0-0.2,0.1-0.3,0.1C100.6,45,100.7,45,100.8,44.9z'
            />
            <path
              fill='#FFFFFF'
              d='M99.9,45.2c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.1-0.4,0.1-0.6,0.1C99.8,45.3,99.8,45.2,99.9,45.2z'
            />
          </g>
        </g>
        <g id='O_-_Sprechblase' display='none'>
          <polyline
            display='inline'
            fill='none'
            points='32.4,-250.9 645.6,-250.9 645.6,443.2 32.4,443.2 	'
          />
          <g display='inline'>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M29.8-130.3c-5.9,0-11.1-1.2-15.8-3.7s-8.3-5.9-11-10.4c-2.7-4.4-4-9.4-4-15s1.3-10.6,4-15s6.3-7.9,11-10.4
					s9.9-3.7,15.8-3.7c5.1,0,9.7,0.9,13.8,2.7s7.5,4.4,10.3,7.8l-10.1,9.1c-3.6-4.4-8-6.6-13.2-6.6c-3,0-5.8,0.7-8.1,2
					c-2.4,1.3-4.2,3.2-5.5,5.6c-1.3,2.4-2,5.2-2,8.4s0.7,5.9,2,8.4c1.3,2.4,3.1,4.3,5.5,5.6s5.1,2,8.1,2c5.2,0,9.6-2.2,13.2-6.6
					l10.1,9.1c-2.7,3.4-6.1,6-10.3,7.9C39.6-131.2,34.9-130.3,29.8-130.3z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M84.2-131.5L84-161.1L69.6-137h-7l-14.3-23.4v28.9H33.6v-56h13l19.7,32.3l19.2-32.3h13l0.2,56H84.2z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M95.7-160.7c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H52.7v-56
					h28.6c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C100-163.6,98.1-161.9,95.7-160.7z M68.3-176v10.8
					h10.9c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L68.3-176L68.3-176z M81.6-142.9c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H68.3v11.4
					H81.6z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M68.1-187.5h15.8v43.4h26.7v12.6H68.1V-187.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M125.2-143.7v12.3h-45v-56h43.9v12.3H95.9v9.4h24.9v11.8H95.9v10.2H125.2z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M131.2-142.3h-23.7l-4.4,10.9H87l24.7-56h15.6l24.8,56h-16.5L131.2-142.3z M126.6-154l-7.2-17.9l-7.2,17.9
					H126.6z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M135.3-130.3c-8.3,0-14.8-2.3-19.4-6.8s-6.9-11-6.9-19.3v-31h15.8v30.6c0,9,3.5,13.4,10.6,13.4
					c7,0,10.6-4.5,10.6-13.4v-30.6h15.6v31c0,8.3-2.3,14.8-6.9,19.3C150-132.6,143.6-130.3,135.3-130.3z'
                />
              </g>
            </g>
          </g>
          <polyline
            display='inline'
            fill='none'
            points='33.3,-250.5 646.5,-250.5 646.5,443.5 33.3,443.5 	'
          />
          <g display='inline'>
            <path
              fill='#0096DC'
              d='M66.9,46.8c-0.9-1.7-1.7-3.4-2.2-5.2v6.1c0,2.8,1,5.5,2.7,7.5c0.1-0.4,0.2-0.9,0.2-1.3
			C67.9,51.3,67.4,48.6,66.9,46.8z'
            />
            <path
              fill='#0096DC'
              d='M76.7,59.7h5.6c-1.3-0.5-2.6-1-3.8-1.7c-4.7-2.5-8.4-6-11.1-10.4c-0.2-0.3-0.3-0.6-0.5-0.8
			c0.5,1.8,0.9,4.5,0.7,7.1c-0.1,0.5-0.1,0.9-0.2,1.3C69.6,58,72.9,59.7,76.7,59.7z'
            />
          </g>
          <g display='inline'>
            <path
              fill='#0096DC'
              d='M76.7,59.7c-3.8,0-7.1-1.8-9.3-4.5c-0.9,3.9-3.2,5.2-2.5,6.7c1.2,2.4,8.3-0.5,19.7-1.1c0.4,0,0.7,0,1.1,0
			c-1.2-0.3-2.3-0.6-3.4-1h-5.6V59.7z'
            />
            <path
              fill='#0096DC'
              d='M85.7,60.7c11.2-0.5,17.6,1.2,18.3-0.9c0,0,0-0.1,0-0.2H82.3C83.4,60.1,84.6,60.5,85.7,60.7z'
            />
            <path
              fill='#0096DC'
              d='M78.5,7.3c4-2.1,8.4-3.3,13.2-3.7h-15c-6.6,0-12,5.4-12,12v8.1c0.7-2.1,1.5-4.1,2.7-6
			C70.1,13.3,73.8,9.8,78.5,7.3z'
            />
            <path
              fill='#0096DC'
              d='M112.1,3.6H97.2c4.8,0.3,9.3,1.5,13.2,3.7c4.7,2.5,8.4,6,11.1,10.4c1,1.7,1.9,3.5,2.5,5.4v-7.5
			C124.1,9,118.7,3.6,112.1,3.6z'
            />
            <path
              fill='#0096DC'
              d='M110.4,58c-1.2,0.7-2.5,1.2-3.8,1.7h5.5c6.6,0,12-5.4,12-12v-5.5c-0.6,1.9-1.5,3.7-2.5,5.4
			C118.9,52,115.2,55.5,110.4,58z'
            />
            <path
              fill='#0096DC'
              d='M110.4,7.3c-4-2.1-8.4-3.3-13.2-3.7h-5.4C86.9,4,82.5,5.2,78.5,7.3c-4.7,2.5-8.4,6-11.1,10.4
			c-1.2,1.9-2,3.9-2.7,6v17.9c0.6,1.8,1.3,3.5,2.2,5.2c-0.2-0.8-0.4-1.5-0.5-1.9c-0.9-2.9-1.6-3.3-1.5-5c0-2.8,1.6-6.5,3.7-6.8
			c4.5-0.6,5.7,15.2,15.7,19.1C89,54.1,92.5,51,98.4,54c2.7,1.4,5.9,4,5.6,5.7h2.6c1.3-0.5,2.6-1,3.8-1.7c4.7-2.5,8.4-6,11.1-10.4
			c1-1.7,1.9-3.5,2.5-5.4V23.1c-0.6-1.9-1.5-3.7-2.5-5.4C118.9,13.3,115.2,9.8,110.4,7.3z M111.7,37.8c0,6.6-5.4,12-12,12H89
			c-6.6,0-12-5.4-12-12V27.2c0-6.6,5.4-12,12-12h10.7c6.6,0,12,5.4,12,12V37.8z'
            />
            <path
              fill='#0096DC'
              d='M98.4,54c-5.9-3.1-9.4,0.1-14.2-1.9C74.3,48.2,73,32.4,68.5,33c-2,0.3-3.6,4-3.7,6.8c0,1.7,0.7,2.1,1.5,5
			c0.1,0.4,0.3,1.1,0.5,1.9c0.2,0.3,0.3,0.6,0.5,0.8c2.7,4.4,6.4,7.9,11.1,10.4c1.2,0.7,2.5,1.2,3.8,1.7H104
			C104.3,58.1,101.1,55.4,98.4,54z'
            />
          </g>
        </g>
        <g id='var_2' display='none'>
          <g display='inline'>
            <g transform='translate(5.291142, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M29.4-130.4c-5.9,0-11.1-1.2-15.8-3.7s-8.3-5.9-11-10.4c-2.7-4.4-4-9.4-4-15s1.3-10.6,4-15s6.3-7.9,11-10.4
					s9.9-3.7,15.8-3.7c5.1,0,9.7,0.9,13.8,2.7s7.5,4.4,10.3,7.8L43.4-169c-3.6-4.4-8-6.6-13.2-6.6c-3,0-5.8,0.7-8.1,2
					c-2.4,1.3-4.2,3.2-5.5,5.6c-1.3,2.4-2,5.2-2,8.4s0.7,5.9,2,8.4c1.3,2.4,3.1,4.3,5.5,5.6s5.1,2,8.1,2c5.2,0,9.6-2.2,13.2-6.6
					l10.1,9.1c-2.7,3.4-6.1,6-10.3,7.9C39.1-131.3,34.5-130.4,29.4-130.4z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(100.341858, 192.113071)'>
              <g>
                <path
                  fill='#0096DC'
                  d='M83.7-131.5l-0.2-29.7L69.1-137h-7l-14.3-23.4v28.9H33.1v-56h13l19.7,32.3L85-187.5h13l0.2,56H83.7z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(157.58489, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M95.2-160.7c3.2,1.1,5.7,2.9,7.5,5.3c1.8,2.4,2.7,5.2,2.7,8.6c0,4.9-2,8.7-5.9,11.4s-9.7,4-17.1,4H52.2v-56
					h28.6c7.1,0,12.6,1.3,16.3,4c3.7,2.6,5.6,6.2,5.6,10.7c0,2.7-0.7,5.1-2,7.2C99.5-163.6,97.6-161.9,95.2-160.7z M67.9-176.1v10.8
					h10.9c5.3,0,8-1.8,8-5.5c0-3.6-2.7-5.4-8-5.4L67.9-176.1L67.9-176.1z M81.2-142.9c5.5,0,8.3-1.9,8.3-5.7s-2.8-5.7-8.3-5.7H67.9
					v11.4H81.2z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(203.730101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M67.6-187.5h15.8v43.4h26.7v12.6H67.6V-187.5z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(240.334237, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M124.7-143.8v12.3h-45v-56h43.9v12.3H95.5v9.4h24.9v11.8H95.5v10.2H124.7z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(280.658792, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M130.8-142.4h-23.7l-4.4,10.9H86.5l24.7-56h15.6l24.8,56h-16.5L130.8-142.4z M126.1-154.1l-7.2-17.9
					l-7.2,17.9H126.1z'
                />
              </g>
            </g>
          </g>
          <g display='inline'>
            <g transform='translate(327.824101, 192.113071)'>
              <g>
                <path
                  fill='#004563'
                  d='M134.8-130.4c-8.3,0-14.8-2.3-19.4-6.8s-6.9-11-6.9-19.3v-31h15.8v30.6c0,9,3.5,13.4,10.6,13.4
					c7,0,10.6-4.5,10.6-13.4v-30.6h15.6v31c0,8.3-2.3,14.8-6.9,19.3C149.6-132.6,143.1-130.4,134.8-130.4z'
                />
              </g>
            </g>
          </g>
          <path
            display='inline'
            fill='#0096DC'
            d='M121.1,17.7c-2.7-4.4-6.4-7.9-11.1-10.4s-10-3.8-16-3.8c-5.9,0-11.2,1.3-16,3.8c-4.7,2.5-8.4,6-11.1,10.4
		s-4,9.4-4,15s1.3,10.5,4,15C69.6,52,73.3,55.5,78,58s10,3.8,16,3.8c5.9,0,11.3-1.3,16-3.8s8.4-6,11.1-10.4s4-9.4,4-15
		S123.8,22.1,121.1,17.7z'
          />
          <path
            display='inline'
            fill='#FFFFFF'
            d='M101.6,50.3H87.4c-6.6,0-12-5.4-12-12v-9.9c0-6.6,5.4-12,12-12h14.2c6.6,0,12,5.4,12,12v9.9
		C113.6,44.9,108.2,50.3,101.6,50.3z'
          />
          <path
            display='inline'
            fill='#FFFFFF'
            d='M82.2,49.9c-0.4-0.5,0.9-1.5,0.9-3.3c0-0.4-0.1-0.9-1.4-3.3c-1.2-2-2.2-3.4-2-3.5c0.3-0.2,5.4,3.3,5.7,3.2
		c0.1,0,0.1,0,0.1,0s0.1,0,0.2-0.1c1.9-0.7,5.4,0.3,5.4,0.3h0.1c0.6,0.2,5.3,1.5,5.2,2.7c0,0.6-1.5,1-3.3,1.4
		c-2.1,0.5-2.4,0.3-4.3,0.8c-1.3,0.4-1.3,0.5-3.4,1.1C82.6,50.2,82.3,50,82.2,49.9z'
          />
          <path
            display='inline'
            fill='#FFFFFF'
            d='M80.6,48.4c-0.2,1.4-0.7,2.4-1.1,3.3c-1.6,3.3-4,5.6-5.8,6.9c5,0.7,8.9,0.1,11.5-0.5
		c10.8-2.5,16.3-10,16.6-12.1c0.1-1.1-0.3-2-0.3-2c-0.4-0.8-1.1-1.1-1.5-1.4c-2.9-1.9-6.3-2.7-9.8-3c-2.4-0.2-4.8-0.1-7.2,0.1
		c-0.7,0-2.2-0.2-2.8,0.3c-0.5,0.4-0.1,1.2,0.2,2.4C80.3,42.5,81,45.5,80.6,48.4z'
          />
        </g>
        <g id='var_3' display='none'>
          <path
            display='inline'
            fill='#FFFFFF'
            d='M87.8,36.9c-0.4-0.5,0.9-1.5,0.9-3.3c0-1-0.5-1.7-1.4-3.2c-1.2-1.9-2.4-3.3-2.3-3.4c0.2-0.2,5.7,3.2,5.9,3.1
		c0.1,0,0.1,0,0.1,0s0.1,0,0.2-0.1c1.9-0.7,5.4,0.3,5.4,0.3h0.1c0.6,0.2,5.3,1.5,5.2,2.7c0,0.6-1.5,1-3.3,1.4
		c-2.1,0.5-2.4,0.3-4.3,0.8c-1.3,0.4-1.3,0.5-3.4,1.1C88.3,37.3,87.9,37.1,87.8,36.9z'
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default CombleauLogo
