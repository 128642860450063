// hooks
import { useContext } from 'react'
import { useAppSelector } from '../../../../redux/hooks'

// mui
import {
  Stack,
  FormControlLabel,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

// components
import DropdownSelect from '../../DropdownSelect'

// types
import { ChangeEvent, MouseEvent, FC } from 'react'
import {
  DividerElement,
  MuiColor,
  StyleElement,
} from '../../../../types/redux/layout'

// assets
import { textFieldStyleProps } from '../../../../assets/helpers/mui'
import { muiColorIcons } from '../../../../assets/settings'
import { LanguageContext } from 'src/components/providers/LanguageProvider'
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
} from '@mui/icons-material'

interface DividerElementSettingsProps {
  idx: number
  handleUpdateStyle: (options: Partial<StyleElement>) => {
    payload: {
      idx: number
      options: Partial<StyleElement>
    }
    type: string
  }
}

const DividerElementSettings: FC<DividerElementSettingsProps> = ({
  idx,
  handleUpdateStyle,
}) => {
  const languageContext = useContext(LanguageContext)

  const element = useAppSelector((state) => state.layout[idx] as DividerElement)

  return (
    <Stack direction='column' spacing={2}>
      <Stack direction='row' spacing={2} justifyContent='space-between'>
        <FormControlLabel
          control={
            <Switch
              color='secondary'
              checked={Boolean(element.text)}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleUpdateStyle({ text: event.target.checked })
              }
            />
          }
          label={languageContext.dictionary.inputLabelDividerText}
        />
        {element.text && (
          <ToggleButtonGroup
            size='small'
            value={element.alignment}
            exclusive
            onChange={(
              _: MouseEvent<HTMLElement>,
              alignment: typeof element.alignment
            ) => alignment !== null && handleUpdateStyle({ alignment })}
          >
            <ToggleButton value={'left' as typeof element.alignment}>
              <FormatAlignLeft />
            </ToggleButton>
            <ToggleButton value={'center' as typeof element.alignment}>
              <FormatAlignCenter />
            </ToggleButton>
            <ToggleButton value={'right' as typeof element.alignment}>
              <FormatAlignRight />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {element.text && (
          <Stack direction='row' spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  disabled={!Boolean(element.text)}
                  checked={Boolean(element.chip)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleUpdateStyle({ chip: event.target.checked })
                  }
                />
              }
              label={languageContext.dictionary.inputLabelDividerChip}
            />
          </Stack>
        )}
      </Stack>
      {element.chip && (
        <DropdownSelect
          {...{
            label: languageContext.dictionary.inputLabelColor,
            iconMatches: muiColorIcons,
            value: element.color,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              handleUpdateStyle({
                color: event.target.value as MuiColor,
              }),
            color: 'secondary',
          }}
        />
      )}

      {element.text && (
        <TextField
          {...textFieldStyleProps(languageContext.dictionary.inputLabelContent)}
          color='secondary'
          disabled={!element.text}
          value={element.content || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateStyle({ content: event.target.value })
          }
        />
      )}
    </Stack>
  )
}

export default DividerElementSettings
